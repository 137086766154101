import React, {
  Fragment,
  useContext,
  useState
} from "react";

import { Fade } from 'react-slideshow-image';
import Postview from "../components/Postview";
import Load from "../components/Load";
import { PostsContext } from "../context/postsContext";

export default function Home() {

  const cdn = 'https://files.geetsuhane.com/';
  const { posts, loadMore,hasMore, topList, topShow,auditionResult,flash } = useContext(PostsContext);
  const [more, setMore]=useState("");
//console.log(flash);
  const promo='https://files.geetsuhane.com/promo/promo.mp4'
function loadMorePosts(){
  setMore(true);
}
  console.log(auditionResult)

  return (  
    <Fragment>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {/* <Storyslider />
                <Createpost /> */}
                  <div className="card d-block border-0 shadow-000 rounded-xxxl bg-gradiant-bottom overflow-hidden cursor-pointer mb-3 mt-0 me-3">
                    
                    <div className="card d-block border-1 shadow-000 d-flex rounded-3 ">
                    <span className="blink">News Flash</span>
                    <div className="card-body p-4 w-100 bg-red-gradiant border-0 d-flex rounded-3"> 
                                     {/* <marquee className="font-xs text-white" direction="left">Voting for GS Titles - princess and vocalist is open, please check for more </marquee> */}
                                     <marquee className="font-xs text-white" direction="left">{flash}</marquee>
                        </div>
                    
                <video src={promo+"#t=0.001"} autoPlay={true} muted loop playsInline type="video/mp4" width="100%" height="100%" controls></video>
              
              
                                  
                          <div style={{paddingBottom:'10px'}}></div>
                 
                   {auditionResult.length>0?
                     
                     (
                      <div className="card-body  w-100 bg-current border-0 d-flex rounded-3 ">
                        <label  className="fw-700 font-xsss mt-3 mb-0 text-center text-white">1st Audition (Leading Singers) </label> 
                      </div>
                      ) : ""
                   } 
                        
                    {auditionResult?.length > 0 &&
                    auditionResult.map((item, index) => {     
                    
                      return (
                      
                        <React.Fragment key={index}>
                         <div key={index} className="col-md-8 col-sm-4 pe-2 ps-2">
                                            <div >                                                                                                                                              
                                                    <label className="fw-700">{item.Name} : {item.Points} </label>                                                                                                                                                                                 
                                            </div>
                                           
                                        </div> 
                                     
                        </React.Fragment>
                      );
                    })}{" "}
                    </div>
                  <div >
                  <Fade>                  
                    {topShow.map((fadeImage, index) => (
                      <div key={index}>
                        <img style={{  maxHeight: '800px', maxWidth:'800px' }}  src={cdn+''+fadeImage.image} />
                        {/* <h2>{topShow.caption}</h2> */}
                      </div>
                    ))}
                  </Fade>
          </div>
                </div>
             
                {topList?.length > 0 &&
                    topList.map((item, index) => {     
                      //console.log(item);
                      return (
                      
                        <React.Fragment key={index}>
                        
                          {/* {number === index ? <Memberslider /> : ""} */}
                          <Postview
                            id={item.id}
                           
                            postimage={item.mediaType== 'img' ? cdn+'users/'+item.mediaUrl:'' }
                            postvideo={item.mediaType== 'video' ? cdn+'users/'+item.mediaUrl:'' }
                            avater={item.picture?(item.picture.includes('googleusercontent')?item.picture:cdn+'users/'+item.picture):""}
                            user={item.name?item.name:''}
                            time={item.createdAt}
                            des={item.text}
                            item={item}
                          />
                        
                        </React.Fragment>
                      );
                    })}{" "}
                {/* <InfiniteScroll
                  dataLength={posts.length}
                  next={loadMore}
                  hasMore={hasMore}
                  loader={<Load />}
                  bufferPx ="5"
                > */}
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">                                                                               
                  <a onClick={loadMorePosts}
                                                   
                   className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block  bg-current font-xsssss fw-700 ls-lg text-white"> {more!=true?"More":"Keep crolling.."}</a>
                 
                                          </div>
                                          {more!=true?
               <Load/>:""}
             
                  {posts?.length > 0 && more==true &&
                    posts.map((item, index) => {     
                      //console.log(index);                      
                      return (
                       
                        <React.Fragment key={index}>
                          {/* {number === index ? <Memberslider /> : ""} */}
                          <Postview
                            id={item.id}                        
                            postimage={item.mediaType== 'img' ? cdn+'users/'+item.mediaUrl:'' }
                            postvideo={item.mediaType== 'video' ? cdn+'users/'+item.mediaUrl:'' }
                            //avater={item.user?.picture? cdn+'users/'+item.user.picture:""}
                            avater={item.user?.picture?(item.user.picture.includes('googleusercontent')?item.user.picture:cdn+'users/'+item.user.picture):""}
                            user={item.user?.name}
                            time={item.createdAt}
                            des={item.text}
                            item={item}
                          />
                         
                        </React.Fragment>
                      );
                    })}{" "}
                {/* </InfiniteScroll> */}
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
