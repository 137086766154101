import React, { Fragment, useContext, useState } from "react";

import ChatUsers from "../components/chat/ChatUsers";
import Popupchat from "../components/Popupchat";
import AudioRecorder from "../components/chat/audioRecorder";
import AudioPlayer from "../components/chat/audioPlayer";

import { AuthContext } from "../context/authContext";
import { SocketContext } from "../context/chatContext";
import alertService from "../services/sweetAlert";

export default function Chat() {
  const { currentUser } = useContext(AuthContext);
  const {
    messages,
    sendMessage,
    activeChat,
    messageContainerRef,
    handleSendAudio,
    deleteMessage,
    windowWidth,
    setActiveChat,
  } = useContext(SocketContext);
  const [newMessage, setNewMessage] = useState("");

  const submitHandler = (e) => {
    if (newMessage.length) {
      sendMessage({
        message: newMessage,
        senderId: currentUser.id,
        receiverId: activeChat?.id,
        time: new Date(),
        msgType: "text",
        sender: currentUser,
      });
      setNewMessage("");
    }
  };
  const deleteMessageAction = async (id) => {
    const confirmed = await alertService.confirm(
      "Delete Message",
      "Are you sure you want to delete this message?"
    );

    if (confirmed) {
      // delete the item
      await deleteMessage(id);
      alertService.success(
        "Message Deleted",
        "The message has been successfully deleted."
      );
    }
  };

  return (
    <Fragment>
      {windowWidth < 1600 && !activeChat?.id ? (
        <ChatUsers />
      ) : (
        windowWidth >= 1600 && <ChatUsers />
      )}
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div
            className="middle-sidebar-left pe-0"
            style={{ maxWidth: "100%" }}
          >
            <div className="row">
              <div className="col-lg-12 position-relative">
                <div
                  className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg"
                  ref={messageContainerRef}
                >
                  {windowWidth < 1600 && activeChat?.id && (
                    <div
                      onClick={() => {
                        setActiveChat(null);
                      }}
                      className="chat-back-button"
                    >
                      <i class="ti-arrow-left text-white"></i>
                    </div>
                  )}
                  <div className="chat-body p-3 ">
                    <div className="messages-content pb-5">
                      {messages.length
                        ? messages.map(
                            (
                              {
                                id,
                                message,
                                sender,
                                createdAt = new Date(),
                                msgType,
                              },
                              index
                            ) => {
                              return (
                                <div
                                  key={`${index}${id}`}
                                  className={`message-item ${
                                    sender?.id === currentUser?.id
                                      ? "outgoing-message"
                                      : ""
                                  }`}
                                >
                                  <div className="message-user">
                                    <figure className="avatar">
                                      {sender?.photoURL ? (
                                        <img
                                          className="rounded-img-sm mx-3"
                                          src={sender.photoURL}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-3">
                                          <span className="ti-user font-sm"></span>
                                        </div>
                                      )}
                                    </figure>
                                    <div className="">
                                    <div className="d-flex gap-2">
                                      <h5>{sender?.displayName}</h5>
                                     
                                    </div>
                                    <div className="time">
                                      {new Date(createdAt).toLocaleTimeString()},{new Date(createdAt).toDateString()}
                                      </div>
                                    <div className="message-wrap justify-content-between d-flex align-items-center">
                                    {msgType === "audio" ? (
                                      <AudioPlayer url={message} />
                                    ) : (
                                      message
                                    )}
                                    {sender?.id === currentUser?.id && id && (
                                      <span
                                        onClick={() => {
                                          deleteMessageAction(id);
                                        }}
                                        className="ti-trash cursor-pointer"
                                      ></span>
                                    )}
                                  </div>
                                    </div>
                                  </div>
                                 
                                </div>
                              );
                            }
                          )
                        : ""}

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="chat-bottom dark-bg p-3 shadow-none theme-dark-bg"
                  style={{ width: "98%" }}
                >
                  <form
                    className="chat-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      const functionToCall = newMessage
                        ? submitHandler
                        : handleSendAudio;
                      functionToCall();
                    }}
                  >
                    <AudioRecorder />
                    <div className="form-group">
                      <input
                        value={newMessage}
                        onChange={(e) => {
                          setNewMessage(e.target.value);
                        }}
                        type="text"
                        className="w-100 bg-grey"
                        placeholder="Start typing.."
                      />
                    </div>
                    <button type="submit" className={`bg-current`}>
                      <i className="ti-arrow-right text-white"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
    </Fragment>
  );
}
