import React, { useContext } from 'react'
import FacebookLogin from 'react-facebook-login'
import { AuthContext } from '../../context/authContext'

const Facebook = () => {
  const { login } = useContext(AuthContext)
  const responseFacebook = response => {
    console.log(response)
    localStorage.setItem('userAuth', JSON.stringify(response))
    login(response, 'facebook')
  }

  let fbContent = (
    <FacebookLogin
      appId='445695730806551'
      autoLoad={false}
      fields='name,email,picture'
      callback={responseFacebook}
      cssClass='form-control text-center style2-input text-white fw-600 bg-primary border-0 p-0'
      icon='fa-facebook'
      disableMobileRedirect={true}
    />
  )

  return <div> {fbContent}</div>
}

export default Facebook
