import React, { Component , Fragment, useState , useContext, useEffect} from "react";
import Pagetitle from '../components/Pagetitle';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import profileService from "../services/profile.service";

    export default function Participants() {
       
        const navigate = useNavigate()
        const { currentUser } = useContext(AuthContext);
        const [singers, setSingers] = useState([]);
        const [dummy, setDummy] = useState([]);
       
        if(currentUser==null)
        {
          localStorage.setItem("redirect", "/audition")
          navigate('/login')
        } 
        
  
      
        var dummyList="";
       
        const cdn = "https://files.geetsuhane.com/"
    
        const getSingers = async () => {        
          var singerslist = await profileService.getResult({
            qry: `select distinct u.id as uid , u.name as user, u.picture as imgpath,u.sid, n.event
            from gsglobal.users u join gsglobal.nominations n on u.id=n.uid
            join gsglobal.config c on c.value=n.event and c.status=1 and c.section='Nominations' and MONTH(n.createdAt)=MONTH(CURRENT_DATE) order by u.name`
          });
          setSingers(singerslist);
          setDummy (singerslist);
    
          //console.log(singerslist);
    
        }
        useEffect(() => {
            async function fetchSingers() {
              await getSingers();
            }
            fetchSingers();
          }, []);
    
     

          const searchChange = p => {          
            if(p.length>0) {
                setSingers(
                    singers.filter(singer => {
                      return singer.uname.toLowerCase().includes(p.toLowerCase())
                    })
                  )
            } else {
                //console.log(dummyList);
                setSingers(dummy);
            }
           
          }

       
        return (
            <Fragment> 
            

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12">
                                    <Pagetitle header="Participants" title="Participants of all active events " onChange={searchChange}/>
                                  

                                    <div className="row ps-2 pe-2">

                                        {singers.map((value , index) => (
                                        <div key={index} className="col-md-6 col-sm-4 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                               <a  href={`/userpage?uid=${value?.uid}`}>
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                    <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1"><img src={value.imgpath?(value.imgpath.includes('googleusercontent')?value.imgpath:'https://files.geetsuhane.com/users/'+value.imgpath):`assets/images/user.png`} alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" /></figure>
                                                    <div className="clearfix w-100"></div>
                                                    <p className="fw-700 font-xsss mt-3 mb-0">{value.user} </p>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.sid}</p>       
                                                    <h4 className="fw-700 font-xsss mt-3 mb-0">{value.event}</h4>                                                                                                     
                                                </div>
                                                  </a>
                                            </div>
                                           
                                        </div> 
                                        
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
