import React, { Component , Fragment,useEffect,useState } from "react";
import profileService from "../services/profile.service";
import Pagetitle from "../components/Pagetitle";


function Grps() {

    const [groupList, setGroupList] = useState([]);
    const cdn = "https://files.geetsuhane.com/"

    const getGroups = async () => {        
      var grpList = await profileService.getResult({
        qry: 'select name,pic from gsglobal.grps order by name'
      });
      setGroupList(grpList);

      console.log(groupList);

    }
    useEffect(() => {
        async function fetchGroups() {
          await getGroups();
        }
        fetchGroups();
      }, []);
        return (
            <Fragment> 
            

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12">
                                    
                                    <Pagetitle header="Groups"/>
                                    
                                    <div className="row ps-2 pe-1">
                                        {groupList.map((value , index) => (
                                        
                                        <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                                                <div className="card-body position-relative h500 bg-image-cover bg-image-center" style={{backgroundImage: `url("${cdn+value.pic}")`}}></div>
                                                <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                                                    {/* <figure className="avatar position-absolute w75 z-index-1 left-15" style={{marginTop: `-40px` }}><img src={`assets/images/${value.pic}`} alt="avater" className="float-right p-1 bg-white rounded-circle w-100 " /></figure> */}
                                                    <div className="clearfix"></div>
                                                    <h4 className="fw-700 font-xsss mt-3 mb-1">{value.name}</h4>                                           
                                                    <span className="position-absolute right-15 top-0 d-flex align-items-center">
                                                   
                                                        <a href="/defaultgroup" className="text-center p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">Detail</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        ))}

                                    </div>
                                </div>               
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }


export default Grps;   