import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import authService from "../services/auth.Service";
import toastr from "../services/toastr.service";
import "toastr/build/toastr.min.css";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const login = async (user, method) => {
    try {
      if (method === "google") {
        const { name, email, picture, sub } = user;
        const userExists = await authService.userExists(sub);
        let u = null;
        if (!userExists.exists) {
          u = await authService.login({
            name,
            email,
            picture,
            uid: sub,
            authProvider: method,
          });
          localStorage.getItem("redirect")? (navigate(localStorage.getItem("redirect"))) :
          navigate(
            location.pathname.includes("login") ? "/" : location.pathname
          )
        }
        setCurrentUser(userExists.exists ? userExists.user : u);
        setIsLoggedIn(true);
        localStorage.setItem(
          "userAuth",
          JSON.stringify(userExists.exists ? userExists.user : u)
        );
      }
      if (method === "facebook") {
        const { name, email, picture, id, graphDomain } = user;
        const userExists = await authService.userExists(id);
        let u = null;
        if (!userExists.exists) {
          u = await authService.login({
            name,
            email,
            picture: picture.data.url,
            authProvider: graphDomain,
            uid: id,
          });
          navigate(
            location.pathname.includes("login") ? "/" : location.pathname
          );
        }

        setCurrentUser(userExists.exists ? userExists.user : u);
        setIsLoggedIn(true);
        localStorage.setItem(
          "userAuth",
          JSON.stringify(userExists.exists ? userExists.user : u)
        );
      }
      localStorage.getItem("redirect")? (navigate(localStorage.getItem("redirect"))) :
      navigate(
        location.pathname.includes("login") ? "/" : location.pathname
        )
    } catch (error) {
      console.log(error);
    }
  };
  const loginWithEmailPassword = async ({ email, password }) => {
    const { user, token } = await authService.loginWithEmailAndPassword({
      email,
      password,
    });
    if (token) {
      localStorage.setItem("userAuth", JSON.stringify(user));
      setCurrentUser(user);
      setIsLoggedIn(true);
      localStorage.getItem("redirect")? (navigate(localStorage.getItem("redirect"))) :
      navigate(
        location.pathname.includes("login") ? "/" : location.pathname
        )
      toastr.success("you are loggedin successfully");

      return;
    }

    toastr.error("email or password is not correct");
    return;
  };

  const register = async ({ name, email, password }) => {
    try {
      const newUser = await authService.register({ name, email, password });
      if (newUser.token) {
        // console.log(newUser);
        // localStorage.setItem("userAuth", JSON.stringify(newUser));
        // setCurrentUser(newUser);
        // setIsLoggedIn(true);
        toastr.success("you are registered successfully, please login");
        navigate("/login");
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.clear();
    setCurrentUser(null);
    setIsLoggedIn(false);
    navigate("/login");
  };

  const contextValues = {
    isLoggedIn,
    currentUser,
    login,
    logout,
    loading,
    loginWithEmailPassword,
    register,
  };

  const autoLogin = async () => {
    setLoading(true);
    const user = await JSON.parse(localStorage.getItem("userAuth"));
    if (user?.name) {
      setCurrentUser(user);
      setIsLoggedIn(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    autoLogin();
  }, []);

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
