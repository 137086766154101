import React, { Component , Fragment, useState , useContext, useEffect} from "react";
import Pagetitle from '../components/Pagetitle';
import ReactSlider from 'react-slider';
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';
import eventService from "../services/event.service";
import alertService from '../services/sweetAlert'

//import { EventContext } from "../context/eventContext";
    export default function Judge() {
       
        //const [rating, setRating] = useState("");
        const [members, setMembers] = useState([]);
        const [dummy, setDummy] = useState([]);
        const navigate = useNavigate()
        const { currentUser } = useContext(AuthContext);
        const [event, setEvent] = useState('Closed');

        const queryParams = new URLSearchParams(window.location.search)
        const term = queryParams.get("vote")  
       
      
        if(currentUser==null)
        {
          localStorage.setItem("redirect", "/judge?vote="+term)
          navigate('/login')
        } 
        // else {
        //   if(currentUser.lead===0 && term==='Judge') {
        //      alertService.vote(
        //       'Not Authorized',
        //       'You are not authorized to view this page'
        //     )
        //     navigate('/event')
        //   }
        
    //console.log(term);
    const voteSubmit = async(id,title,text,purpose) => {
        var rating =await  alertService.vote(
            title,
            text
          )
          console.log(rating)
        if (rating) {
            console.log(rating)
          rating=  currentUser?.lead?rating*20:rating;
            saveRating(id,rating,purpose);
            alertService.success(
            'Thank You!',
            'Your vote has been successfully recorded.'
            )
        }
    }
    

        const saveRating = async (singer,rating,purpose) => {            
            await eventService.saveRatings({
              judgeid: currentUser.id,
              singerid: singer,  
              eventid: purpose,            
              rating:rating
            });
            //getSingers();          
          };

          const getList = async () => {    
           

            var grpList=""
    
          

            var eventres = await profileService.getResult({
                qry: `select c.value as value from gsglobal.config c where c.section='vote' and c.key='${term}' and c.status=1`     
              });
            console.log(eventres);
              if(eventres.length>0) {
                setEvent(eventres[0].value)
              } else {
                setEvent('Closed')
              }

              if(term=="Titles") {
                grpList = await profileService.getResult({
                    qry: `SELECT  distinct u.id,u.name as uname,u.picture as imgpath, n.event as Purpose
                    FROM gsglobal.users u join nominations n on u.id=n.uid join gsglobal.config c on c.key='Titles' and c.value=n.event
                    where u.lead=0 and u.picture is not null
                    order by u.name`})
                   
              } 
             else if (term=="Custom") {
                grpList = await profileService.getResult({
                    qry: `SELECT u.id, u.name as uname, u.picture as imgpath, c.value as Purpose  FROM gsglobal.users u left join gsglobal.config c on c.key='Custom'  where u.lead=0 and u.name is not null
                    order by u.name`})
                } else {
                    grpList = await profileService.getResult({
                        qry: `SELECT  distinct u.id,u.name as uname,u.picture as imgpath, n.event as Purpose
                        FROM gsglobal.users u join nominations n on u.id=n.uid join gsglobal.config c on c.key='${term}' and c.value=n.event
                        and MONTH(n.createdAt)= MONTH(CURRENT_TIMESTAMP) order by u.name`
                      });
                }
           
            setMembers(grpList);
             setDummy(grpList);

            //console.log(grpSingers);
      
          }
     
   

       
            const searchChange = p => {          
                if(p.length>0) {
                    setMembers(
                        members.filter(member => {
                          return member.uname.toLowerCase().includes(p.toLowerCase())
                        })
                      )
                } else {
                    //console.log(dummyList);
                    setMembers(dummy);
                }
               
              }

          useEffect(() => {
            async function fetchList() {
              await getList();
            }
            fetchList();
          }, []);
           
        return (
            <Fragment> 
             
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12">
                                    <Pagetitle header="Vote" title={event} onChange={searchChange}></Pagetitle>

                                    <div className="row ps-2 pe-2">

                                        {members.length>0?members.map((value , index) => (
                                        <div key={index} className="col-md-8 col-sm-4 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                    <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1"><img src={value.imgpath?(value.imgpath.includes('googleusercontent')?value.imgpath:'https://files.geetsuhane.com/users/'+value.imgpath):`assets/images/user.png`} alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" /></figure>
                                                    <div className="clearfix w-100"></div>
                                                    <h4 className="fw-700 font-xsss mt-3 mb-0">{value.uname} </h4>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.user}</p>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.groupname?value.groupname:(value.Purpose?'Vote for '+value.Purpose:'')}</p>
                                                    
                                                  
                                                    <br/>
                                                    {event!='Closed'?(
                                                    <a href="#" onClick={() => voteSubmit(value.id,value.uname,'Your rating for '+(value.Purpose?value.Purpose:{event}),(value.Purpose?value.Purpose:''))}
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Vote</a>
                                                    ):""}
                                                    </div>
                                                  
                                            </div>
                                           
                                        </div> 
                                        
                                        )):""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
