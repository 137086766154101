import React, { Component , Fragment, useState,useEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
import authService from "../services/auth.Service";
import profileService from "../services/profile.service";
import { uploadImages } from "../files";
import alertService from '../services/sweetAlert'


const  Profilephoto = ()  => {
    const { currentUser } = useContext(AuthContext);
  const [images, setImages] = useState([]);

  const [imageName, setImageName] = useState([]);
  
  const { tab1, isOpen } = useState(false);

  const deleteImage = async img => {
    console.log(img.value);

    var filePath = String(img.value).replace("https://cdn.geetsuhane.com/","")
    //console.log(filePath);
    const confirmed = await alertService.confirm(
      'Delete Message',
      'Are you sure you want to delete this message?'
    )

    if (confirmed) {
      // delete the item
      const res = await profileService.deleteAlbum({
        uid: currentUser.id,
        filePath: filePath
      });
      alertService.success(
        'Message Deleted',
        'The message has been successfully deleted.'
      )
    
      setImages(current =>
        current.filter(item => {
          // 👇️ remove object that has id equal to 2
          console.log(item);
          console.log(img.value);
          return item.value !== img.value;
        }),
      );
      
      console.log(images);
      }
  }
  
    
 
   const addImagetoSlider = async (e) => {
    
     if (e.target.files[0] == null) return;
     //setFile(file);
     const image = e.target.files[0];
     const filePath = currentUser.id+'/album/'+ image.name;
     const res = await authService.updateAlbum({
       userId: currentUser.id,
       text: filePath
     });
     if(res.message=='Sucessfully updated!') {
      const uploadTask = await uploadImages(image, filePath);   //bunney.net
      alert(res.message);
      getImagesfromStorage()
     } else {
      alert('Opps, please try after some time');
     }
    
     console.log(res);
   
   };

  

  const getImagesfromStorage = async () => {

    setImageName((imageName) => [...imageName, 'GS']);
    setImages((images) => [...images, 'https://cdn.geetsuhane.com/1598006f-52c0-4c0e-81d7-32424b5f59d4.jpeg']);

    var albumpath = await authService.album({
      userId: currentUser?.id,
    });

    console.log(albumpath);
   
    if(albumpath  !== undefined) {
      for(var i=0; i <albumpath.length; i++){
        setImages((images) => [...images, 'https://cdn.geetsuhane.com/'+albumpath[i].picture]);
      }
    }
    //console.log(images);
  };
  useEffect(() => {
    async function fetchSliderImages() {
      await getImagesfromStorage();
    }
    fetchSliderImages();
  }, []);
        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
             
                <div className="card-body d-flex align-items-center  p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Album</h4>
                    <h4 style={{paddingRight:"10px"}}></h4>
                    <input
                      type="file"
                      id="fileInput"
                      onChange={addImagetoSlider}
                      className="d-none"
                    
                    />
                <label
                  htmlFor="fileInput"
                  style={{ bottom: "7rem", right: "0", cursor: "pointer", height: "40px",
                    width: "40px",borderRadius: "25px",alignContent: "center",justifyContent: "center",display: "flex",
                    alignItems: "center",backgroundColor: "rgba(0,0,0,0.5)",color: "white",zIndex: "1",}} >
                  <i className="feather-edit-3 font-md"></i> 
                </label>
                    
                </div>
                <div className="card-body d-block pt-0 pb-2">
                    <div className="row ps-3 pe-3">
                   
                        {images.map((value , index) => (
                            <div className="col-6 mb-1 p-1" key={index}>
                                                    
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images[tab1].bigImage}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab1: (tab1 + images.length - 1) % images.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + 1) % images.length,
                                            })
                                        }
                                    />
                                    
                                )}
           
                                <div>
                              
                                    <a href="#" onClick={() => deleteImage({value})}>
                                        <img src={`${value}`} alt="Portfolio" className="img-fluid rounded-3 w-100"/>
                                    </a>
                        
                                </div>
                            
                            </div>
                        ))}
                    </div>
                </div>
              
            </div>
        );
    }


export default Profilephoto;