import ApiService from "./api.service";

class ProfileService {
  constructor(apiService) {
    this.apiService = apiService;
  }


  async saveProfile({ uid,dp,pname, location, bio }) {
    
    try {
      const response = await this.apiService.post("/profile/updateProfile", {
        uid,
        pname,
        dp,
        location,
        bio
      });
      return alert(response.message);
    } catch (error) {
      throw new Error('Error: Could not save profile!');
    }
  }
  async getProfile({ uid }) {
    try {
      //console.log(uid);
      const response = await this.apiService.post("/profile/getProfile", {
        uid
      });
     return response.profile;
    } catch (error) {
      throw new Error('Error: Could not fetch profile!');
    }
  }
  async deleteAlbum({ uid,filePath }) {
    try {
      console.log(uid);
      const response = await this.apiService.post("/profile/deleteAlbum", {
        uid,
        filePath
      });
     //return response.profile;
    } catch (error) {
      throw new Error('Error: Could not delete group!');
    }
  }

  async getResult({ qry }) {
    try {
     
      const response = await this.apiService.post("/profile/getResult", {
        qry
      });
      return response;
      //console.log(response);
    } catch (error) {
      throw new Error('Error: Could not fecth result!');
    }
  }

}

// Example usage:
const apiService = new ApiService();
const profileService = new ProfileService(apiService);

export default profileService;
