import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

import { PostsContext } from '../context/postsContext'
import { AuthContext } from '../context/authContext'
import Singleview from '../components/Singleview'
import SinglePostComment from './SinglePostComment'
import alertService from '../services/sweetAlert'


// import GiphySearch from './giphySearch'
// import Tenor from 'react-tenor'
// import 'react-tenor/dist/styles.css'

dayjs.extend(relativeTime)
const cdn = 'https://files.geetsuhane.com/'
export default function PostComments () {
  const navigate = useNavigate()
 
  const { fetchSinglePost, singlePost, saveComment, organizeComments } =
    useContext(PostsContext)
  const { currentUser } = useContext(AuthContext)
  const [content, setContent] = useState('')
  const [parentId, setParentId] = useState(null)
  


  const queryParams = new URLSearchParams(window.location.search)
  const postId = queryParams.get("postId")
  //const postId = localStorage.getItem('postid')
 
  //console.log(postId);

  useEffect(() => {
    if (postId) {
      fetchSinglePost(postId)
    }
  }, [postId])

  const SubmitHandler = async () => {
    setContent('')
    const success = await alertService.success(
      '',
      'Commented successfully'
    )
   
    await saveComment({
      postId: singlePost.id,
      userId: currentUser.id,
      content,
      parentId
    })
   
  }

  const organizedComments = organizeComments(singlePost?.comments)
  //console.log(singlePost?.comments);
  return (
    <div className='main-content right-chat-active'>
      <div className='middle-sidebar-bottom'>
        <div className='middle-sidebar-left'>
          <i
            onClick={() => {
              navigate(-1)
            }}
            className='feather-chevron-left cursor-pointer text-dark text-grey-900 btn-round-sm font-lg'
          ></i>
          <div className='card-body p-0 d-flex align-items-center position-relative'>
            <figure className='avatar me-3'>
            
              {/* {singlePost?.user ? (
                <img
                  src={
                    singlePost?.user?.picture
                      ? cdn + 'users/' + singlePost?.user?.picture
                      : ''
                  }
                  alt='avater'
                  className='shadow-sm rounded-circle w45'
                />
              ) : (
                <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm'>
                  <span className='ti-user font-sm'></span>
                </div>
              )}
              {
                console.log(singlePost)
              } */}
            </figure>
          <div>
                        
                        {/* {
                          console.log(singlePost)
                        } */}
                        <Singleview
                          id={singlePost?.id}
                         
                          postimage={singlePost?.mediaType== 'img' ? cdn+'users/'+singlePost.mediaUrl:'' }
                          postvideo={singlePost?.mediaType== 'video' ? cdn+'users/'+singlePost.mediaUrl:'' }
                          avater={singlePost?.user?.picture? cdn+'users/'+singlePost.user.picture:""}
                          user={singlePost?.user?.name?singlePost.user.name:''}
                          time={singlePost?.createdAt}
                          des={singlePost?.text}
                          item={singlePost}
                        />
                       </div>
            {/* <h4 className='fw-700 text-grey-900 font-xssss mt-1'>
              {' '}
              {singlePost?.user?.name}{' '}
              <span className='d-block font-xssss fw-500 mt-1 lh-3 text-grey-500'>
                {' '}
                {dayjs(singlePost?.createdAt)?.fromNow()}
              </span>
            </h4> */}
          </div>
{
  //console.log(organizedComments)
}
          <p className='text-black' style={{ fontWeight: 'bold' }}>
            Comments ({singlePost?.comments?.length})
          </p>
          <div className='comments_section'>
            {singlePost?.comments?.map(item => {
              return (
                <SinglePostComment
                  key={item.id}
                  item={item}
                  parentId={parentId}
                  setParentId={setParentId}
                />
              )
            })}
          </div>
          
          <div  className='chat-form'>
 
            <div className='form-group'>
                      <input
                        value={content}
                        onChange={e => {
                          setContent(e.target.value)
                        }}
                        type='text'
                        className='w-100 bg-grey'
                        placeholder='Start typing..'
                       
                      />
                    </div>
            <button type='submit' onClick={SubmitHandler}  className={`bg-current`}>
                      <i className='ti-arrow-right text-white w100'></i>
                    </button>
            {/* <button onClick={SubmitHandler} className='bg-transparent px-3'>
              Send
            </button> */}
          </div>
          {/* <Tenor
            token='AIzaSyApa_XEwCVwwSYAfKlKBP9vDLKKdgPIqgI'
            onSelect={result => console.log(result)}
          /> */}
        </div>
      </div>
    </div>
  )
}
