import React, { Component , Fragment, useState,useEffect } from "react";
import { Link,useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
import profileService from "../services/profile.service";
import {uploadImages } from "../files";
import Profilephoto from '../components/Profilephoto';

function Account() {

const { currentUser } = useContext(AuthContext);
const [name, setName] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [location, setLocation] = useState("")
    const [bio, setBio] = useState("")
    const [file, setFile ]= useState("")
    const cdn ='https://files.geetsuhane.com/users/';

    const updateuser = async(name,filePath) => {
        currentUser.name =name;       
        if(filePath.length>0) {
            currentUser.picture = filePath;  
        }
    }
    

    const profileDetails = async () => {
          console.log(currentUser);
        var profie = await profileService.getProfile({
          uid: currentUser?currentUser.id:'',
        });
        console.log(profie);
        if(profie!=null  ) {
            if(profie.length>0) {
            //console.log(profie[0].name);
            let pdetails = profie[0];
            setLocation(pdetails.location?pdetails.location:'');
            setName(pdetails.name?pdetails.name:'');
            setBio(pdetails.bio?pdetails.bio:'');
            setProfileImage(pdetails.picture?''+cdn+pdetails.picture:'')
            updateuser (pdetails.name?pdetails.name:'',pdetails.picture?pdetails.picture:'')
            }
            const pdetails=profie[0];
            
        }
    }
        
    var updateProfile = async () => {
       if(name=="") {
        return alert("name field is empty!")       
       }
       var filePath = ""
       if(file) {
        //console.log('filePath:'+file)
        setProfileImage(URL.createObjectURL(file));
         filePath = currentUser.id+'/profile/'+file.name;
       }
        
       
       
        saveProfile(filePath);
        if(file) {
            const uploadTask = await uploadImages(file,filePath);
        }
        updateuser (name,filePath.length>0?filePath:'')
  
       console.log(currentUser);
      };

      const saveProfile = async (filePath) => {
        
        await profileService.saveProfile({
          uid: currentUser?.id,
          pname: name,  
          location: location,
          bio: bio,
          dp: file?filePath:'',
        })
     
        //setFile('');
        // setFileContent('');
        // setFileType('');
        //setName('');
      };

      const handleFileChange = (e) => {
        const img = e.target.files[0];
        console.log(img);
        setProfileImage(URL.createObjectURL(img));
      
        setFile(img);
       
      };


    const navigate = useNavigate()
    //console.log(currentUser);
  
    if(currentUser==null)
    {
      navigate('/login')
    } 

    useEffect(() => {
        async function fetchProfile() {
          await profileDetails();
        }
        fetchProfile();
      }, []);
// class Account extends Component {
    
        return (
            <Fragment> 
              
                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/userpage" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Account Details</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                   

                                    <form action="#">
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Display Name</label>
                                                    <input type="text" value={name} className="form-control" placeholder="e.g. Naveen" onChange={(e) => {setName(e.target.value)}} />
                                                </div>        
                                            </div>

                                        <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Location</label>
                                                    <input type="text" value={location} className="form-control" placeholder="e.g. Delhi, India (Optional)" onChange={(e) => {setLocation(e.target.value)}} />
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                        <div className="col-lg-12 mb-3">
                                                <label className="mont-font fw-600 font-xsss mb-2 text-dark">Bio</label>
                                                <textarea  className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" value={bio} onChange={(e) => {setBio(e.target.value)}}  placeholder="Write your bio (optional)..." ></textarea>
                                            </div>

                                           
                                        </div>


                                        <div className="row">
                                         

                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                    <div className="row justify-content-center" style={{paddingBottom:'30px'}}>
                                      
                                    </div>
                              
                                                        <div className="form-group mb-0 w-100">
                                                            
                                                            <input type="file" name="file" id="file"  onChange={handleFileChange}  className="input-file" />
                                                            
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                          
                                                                                                     
                                            <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img src={profileImage == '' ? "assets/images/user.png" : profileImage} alt="avater" className="shadow-sm rounded-3 w-100" /></figure>                                                                                                              
                                                <span className="js-fileName">Drag and drop or click to replace  DP</span>
                                                          
                                                            </label>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            

                                            <div className="row justify-content-center">
                                                <a href="#" onClick={updateProfile} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
                                            </div>
                                        </div>

                                    </form>
                                    </div>
                                </div>
                                  <Profilephoto/>      
                            </div>
                        </div>
                       
                    </div>     
                  
                </div>
               
            </Fragment>
        );
        }


export default Account;