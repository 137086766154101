import React, { Component , Fragment, useState , useContext, useEffect} from "react";
import Pagetitle from '../components/Pagetitle';
import profileService from "../services/profile.service";


    export default function Winners() {
        const [singers, setSingers] = useState([]);
        const [stars, setStars] = useState([]);
        const [cc, setCC] = useState([]);
        const [event, setEvent] = useState("");

        const getSingers = async () => {     
           // console.log('call')
            var eventname =""; 
            var eventres = await profileService.getResult({
                qry: `select value from gsglobal.config c where c.section='result' and c.key='display' and status=1`             
              });
            //console.log(eventres[0].value);
              if(eventres) {
                eventname = eventres[0].value
                setEvent(eventres[0].value)
              } else {
                setEvent('Coming Soon!')
              }

            var grpSingers = await profileService.getResult({
              qry: "select * from gsglobal.result r where event='"+eventname+"' and MONTH(r.createdAt)= MONTH(CURRENT_TIMESTAMP)  and status=1 order by stars desc LIMIT 3"
            });
            setStars(grpSingers);

            var grpSingers = await profileService.getResult({
                qry: "select * from gsglobal.result r where event='"+eventname+"' and MONTH(r.createdAt)= MONTH(CURRENT_TIMESTAMP) and status=1 and category='Winner' order by avgscore desc LIMIT 50"
              });
              setSingers(grpSingers);

              var grpSingers = await profileService.getResult({
                qry: "select * from gsglobal.result r where event='"+eventname+"' and  MONTH(r.createdAt)= MONTH(CURRENT_TIMESTAMP) and status=1 and category='CC' order by avgscore desc LIMIT 15"
              });
              setCC(grpSingers);
            
            //console.log(grpSingers);
      
          }
          useEffect(() => {
              async function fetchSingers() {
                await getSingers();
              }
              fetchSingers();
            }, []);
       
       
        return (
            <Fragment> 
             
                <div className="main-content">
                    <div >
                        <div >
                            <div className="row">
                                <div >
                                    <Pagetitle header="Winners"/>
                                    <div className="result-container">
                                    <h1 className="cert-person"> <span style={{color: 'red', paddingBottom:'20px'}}>{event} - Result</span> </h1>
                                      
                                        {stars.map((value , index) => (
                                        <div key={index} id={index}>
                                              <div >      
                                              <div className="cert-person"> {value.uname} (Stars - {value.stars})</div> 
                                             
                                        </div>
                                        </div>
                                        
                                        ))}
                                         {singers.map((value , index) => (
                                        <div key={index} id={index}>
                                              <div >      
                                              <div className="cert-person"> {value.uname} (Points - {value.avgscore})</div> 
                                             
                                        </div>
                                        </div>
                                        
                                        ))}

                                        {cc.map((value , index) => (
                                        <div key={index} id={index}>
                                              <div >      
                                              <div className="cert-person"> {value.uname} (CC)</div> 
                                             
                                        </div>
                                        </div>
                                        
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
