import React, { Component } from "react";
import NewWindow from 'react-new-window'
import { useEffect } from "react";
import { AuthContext } from "../context/authContext";
// import { getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { useState } from "react";
import { useContext } from "react";
// import { logInWithEmailAndPassword, storage } from "../firebase";
// import { getStorage, ref, uploadBytes } from "firebase/storage";
import {uploadFileToStorageDo, uploadImages } from "../files";
import "react-slideshow-image/dist/styles.css";
import profileService from "../services/profile.service";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useRef } from "react";
import authService from "../services/auth.Service";


// import { horizontal } from "@cloudinary/url-gen/qualifiers/gradientDirection";
export default function ProfilecardThree() {
  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState([]);
  const [imageName, setImageName] = useState([]);
  const [name, setName] = useState("");
  const [sid, setSID] = useState("");
  const [location, setLocation] = useState("")
  const [bio, setBio] = useState("")
 
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const sliderRef=useRef(null);
  const { currentUser } = useContext(AuthContext);
  const [profileImage, setProfileImage] = useState("");
  const cdn="https://files.geetsuhane.com/";

  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("uid")
  //console.log(term);



  // const updateProfilePic = async (filePath) => {
  //   //console.log("filePath:"+filePath);
  //   await authService.updateProfilePic({
  //     userId: currentUser.id,
  //     text:filePath
  //   });
  // };


  
  const getImagesfromStorage = async () => {

    setImageName((imageName) => [...imageName, 'GS']);
    setImages((images) => [...images,cdn+ 'album/default/1598006f-52c0-4c0e-81d7-32424b5f59d4.jpeg']);

    var albumpath = await authService.album({
      userId: term?term: currentUser?.id,
    });
    if(albumpath  !== undefined) {
      for(var i=0; i <albumpath.length; i++){
        setImages((images) => [...images, cdn+'users/' +albumpath[i].picture]);
      }
    }
  };

  const profileDetails = async () => {
  
   const refid = term?term: currentUser?.id;
   getProfileImage(refid);
    var profie = await profileService.getProfile({
      uid: refid,
    });

    //console.log(profie);
    if(profie!=null  ) {

      
        const pdetails=profie[0];
        //console.log(profie[0].name);
        setLocation(pdetails.location?pdetails.location:'');
        setName(pdetails.name?pdetails.name:'');
        setBio(pdetails.bio?pdetails.bio:'');
        setSID(pdetails.sid?pdetails.sid:'');
        //setProfileImage(pdetails.picture?''+cdn+'users/'+ pdetails.picture:'')
        
    }

}

 const getProfileImage = async(refid) => {
  var profieimg = await profileService.getResult({
        qry: "select picture from gsglobal.users where id='"+refid+"'",
      });
      setProfileImage(profieimg?(profieimg.includes('googleusercontent')?profieimg:''+cdn+'users/'+ profieimg[0]?.picture):'')
 }

  // const addImagetoSlider = async (e) => {
   
  //   if (e.target.files[0] == null) return;
  //   //setFile(file);
  //   const image = e.target.files[0];
  //   const filePath = currentUser.id+'/album/'+ image.name;
  //   const res = await authService.updateAlbum({
  //     userId: currentUser.id,
  //     text: filePath
  //   });
  //   console.log(res);
  //   //console.log('filePath:'+ filePath);
  //   const uploadTask = await uploadImages(image, filePath);   //bunney.net
  
  // };


  // const UploadProfileImage = async (e) => {
  //   if (e.target.files[0] == null) return;
  //   const file = e.target?.files[0];
  //   setProfileImage(URL.createObjectURL(file));
   
    
  //   const filePath = currentUser.id+'/profile/'+file.name;
  //   //console.log('filePath:'+filePath)

  //   updateProfilePic(filePath);
  //   const uploadTask = await uploadImages(file,filePath);
    
  // };


  useEffect(() => {
    async function fetchSliderImages() {
      await getImagesfromStorage();
    }
    fetchSliderImages();
    profileDetails();
  }, []);

  return (
    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
      <div
        className="card-body  p-0 rounded-xxl overflow-hidden"
       >
       
      

        <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={1500} 
          ref={sliderRef}
          className="w-100  bg-image-cover bg-no-repeat rounded-xxl z-index-0"
          afterChange={(index) => {setActiveImage(imageName[index],index)} 
        } arrows={false} infinite={true}
          >
          {images.map((image, index) => {
            return (
              <div className="each-slide-effect" key={index}>
                <div style={{ backgroundImage: `url(${image})`, height: "370px"}}></div>
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className="card-body p-0 position-relative">
        <figure
          className="avatar position-absolute w100 z-index-1"
          style={{ top: "-250px", left: "30px" }}
        >
         
          <input
            type="file"
            //onChange={UploadProfileImage}
            id="fileInput2"
            className="d-none z-index-2"
          />
          
        
            <img
            src={profileImage == null ? "assets/images/user.png" : profileImage}
            alt="avater"
            className="profileImage  p-1 bg-white rounded-circle w-100 cursor-pointer z-index-2"
            htmlFor="fileInput"
            style={{ zIndex: "2" }}
            // onClick={() => {
            //   document.getElementById("fileInput2").click(); 
            // }}  
            />

   <div  style={{textAlign: "left", color: "white", width:"300px", fontWeight: "bolder", fontSize: "14px"}}>  {name} </div>                                

                  <div  style={{textAlign: "left", color: "white", width:"300px", fontSize: "14px"}}> SID - { sid } </div>
        </figure>
      
        <div className="d-flex align-items-center justify-content-center position-absolute-md right-0 top-0 me-2">
         
        
          <div
            className="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg"
            aria-labelledby="dropdownMenu4"
          >
            <div className="card-body p-0 d-flex">
              <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
              <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                Save Link{" "}
                <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                  Add this to your saved items
                </span>
              </h4>
            </div>
            <div className="card-body p-0 d-flex mt-2">
              <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
              <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                Hide Post{" "}
                <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                  Save to your saved items
                </span>
              </h4>
            </div>
            <div className="card-body p-0 d-flex mt-2">
              <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
              <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                Hide all from Group{" "}
                <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                  Save to your saved items
                </span>
              </h4>
            </div>
            <div className="card-body p-0 d-flex mt-2">
              <i className="feather-lock text-grey-500 me-3 font-lg"></i>
              <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-0">
                Unfollow Group{" "}
                <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                  Save to your saved items
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
        <ul
          className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
          id="pills-tab"
          role="tablist"
        >
            <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
              href=""
              data-toggle="tab"
            >
              About
            </a>
          </li>
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
              href="/accountinformation"
              data-toggle="tab"
            >
              Update Profile
            </a>
          </li>
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
              href="#navtabs2"
              data-toggle="tab"
            >
              Membership
            </a>
          </li>
        
          
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
              href="#navtabs3"
              data-toggle="tab"
            >
              Group
            </a>
          </li>
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
              href="#navtabs1"
              data-toggle="tab"
            >
              Events
            </a>
          </li>
          <li className="list-inline-item me-5">
            <a
              className="fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
              href="#navtabs7"
              data-toggle="tab"
            >
              Media
            </a>
          </li>
        </ul>
      </div>
    </div>
    
  );
}
