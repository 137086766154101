import Swal from 'sweetalert2';

const alertService = {
  confirm: async (title, text, confirmButtonText = 'Yes', cancelButtonText = 'Cancel') => {
    const result = await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    });

    return result.isConfirmed;
  },

  vote: async(title,text,confirmButtonText = 'Submit', cancelButtonText = 'Cancel') => {
    const result =await Swal.fire({
      title,
      icon: 'question',
      input: 'range',
      text,
      inputAttributes: {
        min: 1,
        max: 10,
        step: 1
      },
      inputValue: 5,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    })
    return result.value;
  },


  success: (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
    });
  },

  error: (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'error',
    });
  },

  warning: (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'warning',
    });
  },

  info: (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'info',
    });
  },


};

export default alertService;
