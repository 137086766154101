import React,{Component} from 'react';
import { Link } from 'react-router-dom';

class Appfooter extends Component {
    render() {
        return (
            <div></div>
            /*<div className="app-footer border-0 shadow-lg bg-primary-gradiant">
                <Link to="/home" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
                <Link to="/privacy.html" className="nav-content-bttn"><i className="feather-package"></i></Link>
                <Link to="/community.html" className="nav-content-bttn" data-tab="chats"><i className="feather-layout"></i></Link>           
                <Link to="/terms.html" className="nav-content-bttn"><i className="feather-layers"></i></Link>
                
            </div>   */     
        );
    }
}

export default Appfooter;