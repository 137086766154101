
import AWS from 'aws-sdk'




//idrive

//const regionName = 'ap-southeast-1'
const accessKeyId = '8NCAWPDGd67IvgALxuqs'
const accessSecretKey = 'ni5oAZ7RbkmeB2R4gRneN3ybLruhzX95mVsTjHOM'
const bucketName = 'gsfiles'

const endpointUrl = `sgp1.digitaloceanspaces.com`

const spacesEndpoint = new AWS.Endpoint(endpointUrl)
 const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: 'DO00MRMV7GL7FE4P7WFK',
  secretAccessKey: 'FCCHss3Om3mUndb2axaqnqK6mJfbIEPPDy7nX9R+WHs',
})



//idrive
export function uploadImages(file, filePath) {
  const params = { Body: file, Bucket: bucketName, Key: 'users/'+filePath }
    s3.putObject(params)
    .on('build', request => {
      request.httpRequest.headers.Host = `https://sgp1.digitaloceanspaces.com`
      request.httpRequest.headers['Content-Length'] = file.size
      request.httpRequest.headers['Content-Type'] = file.type
      request.httpRequest.headers['x-amz-acl'] = 'public-read'
    }
    )
    .send((err, data) => {
      if (err) return alert(JSON.stringify(err))
      else { console.log('path:', params.Key);}
          return params.Key;
      // ...
    })
};

//bunny.net

const bunnyKey ='832c834b-c537-4a9b-a44dd414a69f-599d-4217';

export function uploadImagesBunny (file, filePath) {
console.log('filepath:'+filePath);
  const options = {
    method: 'PUT',
    headers: {
      AccessKey: bunnyKey,
      'content-type': 'application/octet-stream'},
      body: file
  };
  
  fetch('https://storage.bunnycdn.com/gsimages/'+ filePath, options)
    .then(response => response.json())
    .then(response => console.log('uploaded'+response))
    .catch(err => console.error(err));

}





