import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import { AuthContext } from "../context/authContext";
import postService from "../services/posts.service";
// import { Link } from "react-router-dom";
// import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert'

import {  useCallback } from 'react'
import Drawer from 'react-bottom-drawer'

dayjs.extend(relativeTime);

export default function Singleview({
  user,
  time,
  des,
  avater,
  postimage,
  postvideo,
  id,
  item,
}) {
  const navigate = useNavigate();
  const relativeTimeString = dayjs(time).fromNow();
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [userReaction, setUserReaction] = useState("");
  const [likes, setLikes] = useState(0);
  const [love, setLove] = useState(0);
  const [comments, setComments] = useState(0);
  const [postReactions, setPostReactions] = useState([]);
  const [isVisible, setIsVisible] = useState(false)
  const openDrawer = useCallback(() => setIsVisible(true), [])
  const closeDrawer = useCallback(() => setIsVisible(false), [])



  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenBottom: false,
  });

  //console.log(avater)

  function parseJSONOrArray(str) {
    try {
      //console.log(str)
      const parsed = JSON.parse(str);
      if (Array.isArray(parsed)) {
        return parsed;
      } else {
        return Array.isArray(str) ? str : [];
      }
     
    } catch (error) {
      return [];
    }
  }

  
  
 
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const deletePostAction = async () => {
    const response = await postService.deletePost(id);
    //console.log(item);
    if (response) {
      setIsDeleted(true);
      setIsOpen(false);
    }
  };

 const sendGift = (soon) => {
  // const success =  alertService.info(
  //   soon,
  //   'Coming soon!'
  // )
  setState({ isPaneOpenBottom: true })
 }

  const reactToPost = async (reaction) => {
   
    if(userReaction && currentUser?.id!='40b7b63d-946b-4d3a-954f-2aa4bc331ce6') {
      return;
    } else {
      reaction=="like"? setLikes(likes+1): setLove(love+1);
    }
   
   
   
    if (currentUser?.id) {
      const response = await postService.reactToPost({
        postId: id,
        userId: currentUser?.id,
        reaction,
      });

      setUserReaction(response.item?.reaction);
      
      return;
    }
    navigate("/login");
  };

  // const queryParams = new URLSearchParams(window.location.search)
  // const postId = queryParams.get("postId")

  useEffect(() => {


    if (item && item?.reactions) {
     
      const arr = parseJSONOrArray(item?.reactions);
      setPostReactions(arr);
      //console.log(item.reactions)
      //console.log(arr.filter((item) => item.reaction === "love").length)
      //console.log(arr.length)
       if(arr.length>0) {
        setLikes(arr.filter((item) => item.reaction === "like").length)
        setLove(arr.filter((item) => item.reaction === "love").length)
       } else {
        setLikes(item?.reactions.filter((item) => item.reaction === "like").length)
        setLove(item?.reactions.filter((item) => item.reaction === "love").length)
        //console.log(item?.reactions.filter((item) => item.reaction === "love").length)
       }
      //console.log(item)
      var commentsCount= item.comments;
      //console.log(commentsCount)
      try{
        setComments(commentsCount>0?commentsCount:"")
      } catch (e) {

      }
    

     
      setUserReaction(
        arr
          .filter((item) => item.userId)
          .find((item) => item.userId === currentUser?.id)?.reaction
      );

    
     
    }
  }, [item, currentUser]);
  //commentsFn();
  return (
    
    <React.Fragment>
    
      {!isDeleted ? (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3" >
          <div className="card-body p-0 d-flex position-relative">
          <a  href={`/userpage?uid=${item?.userId}`}>
            <figure className="avatar me-3">
              {avater ? (
                <img
                  src={avater}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                />
              ) : (
                <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
                  <span className="ti-user font-sm"></span>
                </div>
              )}
            </figure></a> <a  href={`/userpage?uid=${item?.userId}`}>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">
              {" "}
              {user}{" "}
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {" "}
                {relativeTimeString}
              </span>
            </h4></a>

            {(item?.userId === currentUser?.id || currentUser?.id === "40b7b63d-946b-4d3a-954f-2aa4bc331ce6")
               && (
                <React.Fragment>
                  <div
                    className={`ms-auto pointer ${isOpen ? " show" : ""}`}
                    id="dropdownMenu4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleOpen}
                  >
                    <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                  </div>
                  <div
                    className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg  ${
                      isOpen ? " show" : ""
                    }`}
                    aria-labelledby="dropdownMenu4"
                  >
                    <div
                      onClick={deletePostAction}
                      className="card-body p-0 d-flex"
                    >
                      <i className="feather-trash text-grey-500 me-3 font-lg"></i>
                      <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                        Delete Post{" "}
                        <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                          Delete Post
                        </span>
                      </h4>
                    </div>
                  </div>
                </React.Fragment>
              )}
          </div>
          {postvideo ? (
            <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die pointer"  onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?postId=${item.id}` : "/login")
            }}>
              {/* <a href="/defaultvideo" className="video-btn">
                <video autoPlay loop className="float-right w-100">
                  <source src={`${postvideo}`} type="video/mp4" />
                </video>
              </a> */}
              <video
                src={postvideo + "#t=0.001"}
                type="video/mp4"
                width="100%"
                height="100%"
                controls
              ></video>
            </div>
          ) : (
            ""
          )}

          <div className="card-body p-0 me-lg-5">
            <p className="fw-500 text-black-500 lh-26 font-xsss w-100 mb-2">
              {des}{" "}
              {/* <a href="/defaultvideo" className="fw-600 text-primary ms-2">
                {des?.length > 100 ? "See more" : ""}
              </a> */}
            </p>
          </div>
          {postimage?.length ? (
            <div className="card-body d-block p-0 mb-3 pointer"  onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?postId=${item.id}` : "/login")
            }}>
              <div className="row ps-2 pe-2">
                <div className="col-sm-12 p-1">
                  <img src={postimage} className="rounded-3 w-100" alt="post" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="card-body d-flex p-0">
            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
              <i
                onClick={() => {
                  reactToPost("like");
                }}
                className={`feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss ${
                  userReaction === "like" && "reacted"
                }`}
              ></i>
              {/* {postReactions.filter((item) => item.reaction === "like")
                .length || ""} */} {likes!=0?likes:""} <span style={{marginLeft:"10px"}}></span>
              <i
                onClick={() => {
                  reactToPost("love");
                }}
                className={`feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss  ${
                  userReaction === "love" && "reacted"
                }`}
              ></i> 

              

              {/* {postReactions.filter((item) => item.reaction === "love")
                .length || ""} */} {love!=0?love:""} <span style={{marginLeft:"10px"}}></span>
            </div>
            <div className={`emoji-wrap pointer`}>
              <ul className="emojis list-inline mb-0">
                <li className="emoji list-inline-item">
                  <i className="em em---1"></i>{" "}
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-angry"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-anguished"></i>{" "}
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-astonished"></i>{" "}
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-blush"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-clap"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-cry"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-full_moon_with_face"></i>
                </li>
              </ul>
            </div>
            <div
            onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?postId=${item.id}` : "/login")
            }}
              className="d-flex cursor-pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
            >
              <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
              <span className="d-none-xss"> </span> {comments!=0?comments:""}
            </div>

            <div  className="d-flex cursor-pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
            style={{paddingLeft:"10px"}}  onClick={openDrawer}>     <i className="feather-gift text-grey-900 font-sm btn-round-md bg-greylight"></i></div>
            <div
              className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}
              id={`dropdownMenu${id}`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={()=>sendGift('Sharing')}
            >
              <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i>
              <span className="d-none-xs">Share</span>
            </div>
            <div
              className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0`}
              aria-labelledby={`dropdownMenu${id}`}
            >
              <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center" >
                Share{" "}
                <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i>
              </h4>
              <div className="card-body p-0 d-flex">
                <ul className="d-flex align-items-center justify-content-between mt-2">
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-facebook">
                      <i className="font-xs ti-facebook text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-twiiter">
                      <i className="font-xs ti-twitter-alt text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-linkedin">
                      <i className="font-xs ti-linkedin text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-instagram">
                      <i className="font-xs ti-instagram text-white"></i>
                    </span>
                  </li>
                  <li>
                    <span className="btn-round-lg pointer bg-pinterest">
                      <i className="font-xs ti-pinterest text-white"></i>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-body p-0 d-flex">
                <ul className="d-flex align-items-center justify-content-between mt-2">
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-tumblr">
                      <i className="font-xs ti-tumblr text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-youtube">
                      <i className="font-xs ti-youtube text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-flicker">
                      <i className="font-xs ti-flickr text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-black">
                      <i className="font-xs ti-vimeo-alt text-white"></i>
                    </span>
                  </li>
                  <li>
                    <span className="btn-round-lg pointer bg-whatsup">
                      <i className="font-xs feather-phone text-white"></i>
                    </span>
                  </li>
                </ul>
              </div>
              <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">
                Copy Link
              </h4>
              <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
              <input
                type="text"
             
                className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg"
              />
            </div>
            <div className="container">
              
          <center>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
       className="drawer"
      >
        <img className="floating"  height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/silver1.png"></img>
            <img  className="floating"  height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/tea-blue.png"></img>
            <img  className="floating"  height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/rupee.png"></img>
            <img  className="floating"  height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/dollar.png"></img>
            <img  className="floating"  height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/gift.png"></img>
            <img  className="floating"  height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/wine.png"></img>
            <img className="floating"   height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/crown.png"></img>
            <img  className="floating" height={50} width={50} src="https://gsfiles.sgp1.digitaloceanspaces.com/gifts/heart.png"></img>

      </Drawer>
      </center>
      </div>
          </div>
         
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
}
