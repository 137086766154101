import { createContext, useContext, useEffect, useState } from "react";
import postService from "../services/posts.service";
import profileService from "../services/profile.service";
export const PostsContext = createContext([]);

const PAGE_SIZE = 10;

const PostsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [auditionResult, setAuditionResult] = useState([]);
  const [topList, setTopList] = useState([]);
  const [topShow, setTopShow] = useState([]);
  const [singlePost, setSinglePost] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [flash, setFlash] = useState(null);

  const loadMore = async () => {
    if (!hasMore) return;

  var eventres = await profileService.getResult({
      qry: `select value  from gsglobal.config c where c.section='Home' and c.key='Flash' and c.status=1`     
    });
  console.log(eventres);
    if(eventres.length>0) {
      setFlash(eventres[0].value)
    } else {
      setFlash('Stay Tuned!')
    }
   var audition = await profileService.getResult({
    qry: `SELECT u.name as Name, sum(r.rating) as 'Points'  FROM gsglobal.users u  
          join gsglobal.ratings r on u.id=r.singerid left join gsglobal.config c on c.section='audition' and c.key='result' and c.status=1
          where r.event=c.value
          group by u.name  order by sum(r.rating) desc`
        });
    // const audition = await postService.result();
    // {
      console.log(audition);
      if (audition.length) {
        setAuditionResult(audition);
      }
    // }

    const show = await postService.topShow();
    {
      if (show) {
        setTopShow(show.posts);
      }
    }
    const result = await postService.toplist();
    {
      if (result) {
        setTopList(result.posts);
      }
    }
    const response = await postService.getAllPosts(page, PAGE_SIZE);
    if (response) {
      if (response?.posts?.length === 0) {
        setHasMore(false);
      } else {
        setPosts((prevPosts) => [...prevPosts, ...response?.posts]);
        setPage((prevPage) => prevPage + 1);
        setHasMore(posts?.length < response?.totalCount);
      }
    }
  }

  function organizeComments(comments) {
    if (!Array.isArray(comments)) {
      // Return an empty array if comments is not an array
      return [];
    }
  
    const commentsMap = new Map();
    const rootComments = [];
  
    for (const comment of comments) {
      const { id, parentId } = comment;
  
      if (typeof id !== 'string' || id.trim() === '') {
        // Skip the comment if it doesn't have a valid ID
        continue;
      }
  
      if (parentId !== null && (typeof parentId !== 'string' || parentId.trim() === '')) {
        // Skip the comment if it has an invalid parentId
        continue;
      }
  
      if (!commentsMap.has(id)) {
        // If the comment doesn't exist in the map, create a new comment object
        commentsMap.set(id, { ...comment, childComments: [] });
      }
  
      const currentComment = commentsMap.get(id);
  
      if (parentId === null) {
        // If the comment doesn't have a parentId, add it to the root comments
        rootComments.push(currentComment);
      } else if (commentsMap.has(parentId)) {
        // If the parent comment exists, add the current comment as its child
        commentsMap.get(parentId).childComments.push(currentComment);
      }
    }
  
    return rootComments;
  }
  
  

  const fetchSinglePost = async (id) => {
    const post = await postService.getPostById(id);
    setSinglePost(post);
  };
  const saveComment = async ({ postId, userId, content, parentId }) => {
    const post = await postService.commentToPost({
      postId,
      userId,
      content,
      parentId,
    });
    fetchSinglePost(postId)
    // setSinglePost(post);
  };

  useEffect(() => {
    loadMore();

    return () => {
      setPosts([]);
      setPage(0);
      setHasMore(false);
    };
  }, []);

  return (
    <PostsContext.Provider
      value={{
        posts,
        loadMore,
        hasMore,
        topList,
        topShow,
        auditionResult,
        fetchSinglePost,
        singlePost,
        saveComment,
        organizeComments,
        flash
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};

export default PostsProvider;
