//import userEvent from '@testing-library/user-event';
//import { updateCurrentUser } from 'firebase/auth';
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import Darkbutton from "../components/Darkbutton";

import { AuthContext } from "../context/authContext";

import "./customStyles.scss";
class Header extends Component {
  state = {
    isOpen: false,
    isActive: false,
    isNoti: false,
  };
  static contextType = AuthContext;

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleActive = () => this.setState({ isActive: !this.state.isActive });
  toggleisNoti = () => this.setState({ isNoti: !this.state.isNoti });

  render() {
    const { isLoggedIn, currentUser, logout } = this.context;
    const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
    const buttonClass = `${this.state.isOpen ? " active" : ""}`;
    const searchClass = `${this.state.isActive ? " show" : ""}`;
    const notiClass = `${this.state.isNoti ? " show" : ""}`;
    //const img = `${loggedInUserPic ? loggedInUserPic : "assets/images/gs.png"}`;
    const loginOption = `${isLoggedIn ? "Sign Out" : "Sign in"}`;
    //const loginLink = `${loggedInUser ? "logout" : "login"}`;

    const logoutFunction = () => {
      logout();
    };

    return (
      <div className="nav-header bg-white shadow-xs border-0">
        <div className="nav-top">
          <Link to="/">
            {" "}
            <img className="w60" src="https://files.geetsuhane.com/logo/gs.gif"></img>
          </Link>
          <Link to="/gupshup" className="mob-menu ms-auto me-2 chat-active-btn">
            <i className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i>
          </Link>

          <span
            onClick={this.toggleActive}
            className="me-2 menu-search-icon mob-menu"
          >
            <i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i>
          </span>
          <button
            onClick={this.toggleOpen}
            className={`nav-menu me-0 ms-2 ${buttonClass}`}
          ></button>
        </div>

        <form action="#" className="float-left header-search ms-3">
          <div className="form-group mb-0 icon-input">
            <i className="feather-search font-sm text-grey-400"></i>
            <input
              type="text"
              placeholder="Start typing to search.."
              className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
            />
          </div>
        </form>
        {/* <NavLink
          activeclassname="active"
          to="/home"
          className="p-2 text-center ms-3 menu-icon center-menu-icon"
        >
          <i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink> */}
        {/* <NavLink
          activeclassname="active"
          to="/defaultstorie"
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
        >
          <i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink>

        <NavLink
          activeclassname="active"
          to="/defaultgroup"
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
        >
          <i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink>
        <NavLink
          activeclassname="active"
          to="/shop2"
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
        >
          <i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink> */}

        <span
          className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`}
          id="dropdownMenu3"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={this.toggleisNoti}
        >
          <span className="dot-count bg-warning"></span>
          <i className="feather-bell font-xl text-current"></i>
        </span>
        <Darkbutton />
        <Link
          to="/gupshup"
          className="p-2 text-center ms-3 menu-icon chat-active-btn"
        >
          <i className="feather-message-square font-xl text-current"></i>
        </Link>

        {isLoggedIn && (
          <React.Fragment>
            <div className="d-flex gap-2 align-items-center">
              {currentUser?.picture ? (
                <img
                  className="rounded-img-sm mx-3"
                  src={
                    currentUser.picture != "" || !currentUser.picture.includes('sgleusercontent.com')
                      ? "https://files.geetsuhane.com/users/" + currentUser.picture
                      : "/assets/user.png"
                  }
                  alt=""
                />
              ) : (
                <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
                  <span className="ti-user font-sm"></span>
                </div>
              )}
              <p className="d-none d-sm-none d-md-block mb-md-0">
                {currentUser?.name}
              </p>
            </div>
          </React.Fragment>
        )}
        <div className="p-0 ms-3 menu-icon">
          {" "}
          <a
            onClick={logoutFunction}
            className="nav-content-bttn open-font h-auto pt-2 pb-2"
          >
            <span className="cursor-pointer">{loginOption}</span>
          </a>
        </div>

        <nav className={`navigation scroll-bar ${navClass}`}>
          <div className="container ps-0 pe-0">
            <div className="nav-content">
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                <ul className="mb-1 top-content">
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                 
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/buzz"
                      className="nav-content-bttn open-font" >
                      <i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i>
                      <span>Buzz</span>
                    </Link>
                  </li>                 
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/leads"
                      className="nav-content-bttn open-font" >
                      <i className="feather-activity btn-round-md bg-mini-gradiant me-3"></i>
                      <span>Event</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/groups"
                      className="nav-content-bttn open-font"
                    >
                      <i className="feather-user-plus btn-round-md bg-primary-gradiant me-3"></i>
                      <span>Groups</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/gupshup"
                      className="nav-content-bttn open-font">
                      <i className="feather-globe btn-round-md bg-gold-gradiant me-3"></i>
                      <span>Gupshup </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/userpage"
                      className="nav-content-bttn open-font">
                      <i className="feather-user btn-round-md bg-primary-gradiant me-3"></i>
                      <span>Profile </span>
                    </Link>
                  </li>                                 

                 
                </ul>
              </div>

              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                <ul className="mb-3">
                  {/* <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/defaulthotel"
                      className="nav-content-bttn open-font"
                    >
                      <i className="font-xl text-current feather-home me-3"></i>
                      <span>Events</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/defaultevent"
                      className="nav-content-bttn open-font"
                    >
                      <i className="font-xl text-current feather-map-pin me-3"></i>
                      <span>Ranking</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/chat"
                      className="nav-content-bttn open-font h-auto pt-2 pb-2"
                    >
                      <i className="font-sm feather-message-square me-3 text-grey-500"></i>
                      <span>Inbox</span>
                      {/* <span className="circle-count bg-warning mt-0">23</span> */}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/privacy"
                      className="nav-content-bttn open-font"
                    >
                      <i className="font-xl text-current feather-shield me-3"></i>
                      <span>Privacy</span>
                    </Link>
                  </li>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/terms"
                      className="nav-content-bttn open-font h-auto pt-2 pb-2"
                    >
                      <i className="font-sm feather-flag me-3 text-grey-500"></i>
                      <span>Terms</span>
                    </Link>
                  </li>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/community"
                      className="nav-content-bttn open-font h-auto pt-2 pb-2"
                    >
                      <i className="font-sm feather-users me-3 text-grey-500"></i>
                      <span>Community</span>
                    </Link>
                  </li>

                  <li className="cursor-pointer">
                    <a
                      onClick={logoutFunction}
                      className="nav-content-bttn open-font h-auto pt-2 pb-2"
                    >
                      <i className="font-sm feather-settings me-3 text-grey-500 "></i>
                      <span>{loginOption}</span>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                <div className="nav-caption fw-600 font-xssss text-grey-500">
                  <span></span> Account
                </div>
                <ul className="mb-1">
                
                </ul>
              </div> */}
            </div>
          </div>
        </nav>

        <div className={`app-header-search ${searchClass}`}>
          <form className="search-form">
            <div className="form-group searchbox mb-0 border-0 p-1">
              <input
                type="text"
                className="form-control border-0"
                placeholder="Search..."
              />
              <i className="input-icon">
                <ion-icon
                  name="search-outline"
                  role="img"
                  className="md hydrated"
                  aria-label="search outline"
                ></ion-icon>
              </i>
              <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                <i className="ti-close font-xs" onClick={this.toggleActive}></i>
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Header;
