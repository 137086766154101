import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyCAy4zvqjukxkt3pFbFUz2-mVGBjsIceHs",
  // authDomain: "geetsuhane-7066b.firebaseapp.com",
  // projectId: "geetsuhane-7066b",
  // storageBucket: "geetsuhane-7066b.appspot.com",
  // messagingSenderId: "947992019752",
  // appId: "1:947992019752:web:2e84134842c553aef7861d",
  // measurementId: "G-P5VRVHZ88Y",

  apiKey: "AIzaSyBShV0vLw5u_einFTJybaSxHUgT2SH04Vw",
  authDomain: "gs-web-57b1e.firebaseapp.com",
  projectId: "gs-web-57b1e",
  storageBucket: "gs-web-57b1e.appspot.com",
  messagingSenderId: "1066236225695",
  appId: "1:1066236225695:web:727a165c051c0f5053daaa",
  measurementId: "G-72VW41N491"
};
export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);



export { db, storage };
