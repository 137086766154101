import React, { useContext } from 'react'
import { SocketContext } from '../../context/chatContext'
import { AuthContext } from '../../context/authContext'

export default function ChatUsers ({ title }) {
  const { users, activeChat, setActiveChat } = useContext(SocketContext)
  const { currentUser } = useContext(AuthContext)

  return (
    <div
      id='main-content-wrap'
      className={`right-chat nav-wrap mt-2 right-scroll-bar active-sidebar`}
    >
      <div className='middle-sidebar-right-content bg-white shadow-xss rounded-xxl'>
        <div className='section full pt-4 mt-4 position-relative feed-body'>
          <h4 className='font-xsssss text-grey-500 text-uppercase fw-700 px-2 ls-3'>
            {title || 'CONTACTS'}
          </h4>
          <ul className='list-group list-group-flush'>
            {users.length
              ? users.map((value, index) =>
                  value.id !== currentUser.id ? (
                    <li
                      onClick={e => {
                        setActiveChat(value)
                      }}
                      key={index}
                      className={`${
                        value.id === activeChat?.id
                          ? 'bg-grey rounded-2 '
                          : 'bg-transparent'
                      } list-group-item no-icon px-2 pt-2 pb-2 border-0 d-flex align-items-center cursor-pointer`}
                    >
                      <figure className='avatar float-left mb-0 me-2'>
                        {value?.photoURL ? (
                          <img
                            className='rounded-img-sm mx-3'
                            src={currentUser.photoURL}
                            alt=''
                          />
                        ) : (
                          <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm'>
                            <span className='ti-user font-sm'></span>
                          </div>
                        )}
                      </figure>
                      <h3 className='fw-700 mb-0 mt-0'>
                        <span className='font-xssss text-grey-600 d-block text-dark model-popup-chat pointer'>
                          {value.name}
                        </span>
                      </h3>
                      <span>{value.isOnline ? 'online' : 'offline'}</span>
                    </li>
                  ) : (
                    ''
                  )
                )
              : ''}
          </ul>
        </div>
      </div>
    </div>
  )
}
