// import Demo from "./demo/Demo";
import PostComments from "./pages/PostComments"
import Home from "./pages/Home";
//import Nominations from "./pages/Nominations";
import Nominate from "./pages/Nominate";
import Participants from "./pages/Participants";
import Terms from "./pages/Terms";
import Community from "./pages/Community";
import Badge from "./pages/Badge";
import GupShup from "./pages/GupShup";
// import Group from "./pages/Group";
import Storie from "./pages/Storie";

import Member from "./pages/Member";
import Judge from "./pages/Judge";
// import Email from "./pages/Email";
// import Emailopen from "./pages/Emailopen";
import Settings from "./pages/Settings";
import Account from "./pages/Account";
// import Contactinfo from "./pages/Contactinfo";
// import Socialaccount from "./pages/Socialaccount";
// import Password from "./pages/Password";
// import Payment from "./pages/Payment";
// import Notification from "./pages/Notification";
// import Helpbox from "./pages/Helpbox";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Notfound from "./pages/Notfound";

// import ShopOne from "./pages/ShopOne";
// import ShopTwo from "./pages/ShopTwo";
// import ShopThree from "./pages/ShopThree";
import Singleproduct from "./pages/Singleproduct";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Chat from "./pages/Chat";
// import Live from "./pages/Live";
// import Job from "./pages/Job";
// import Event from "./pages/Event";
// import Hotel from "./pages/Hotel";
import Videos from "./pages/Videos";
import Comingsoon from "./pages/Comingsoon";

// import Grouppage from "./pages/Grouppage";
import Userpage from "./pages/Userpage";
// import Authorpage from "./pages/Authorpage";
// import Hotelsingle from "./pages/Hotelsingle";

import PostsProvider from "./context/postsContext";
import EventProvider from "./context/eventContext";
import SocketContextProvider from "./context/chatContext";
import BuzzProvider from "./context/gupShupContext";
import Leads from "./pages/Leads";
import Certificate from "./pages/Certificate";
import Grps from "./pages/Group";
import Audition from "./pages/Audition";
import Result from "./pages/Result";
import Winners from "./pages/Winners";
import Privacy from "./pages/Privacy"

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/`,
    layout:'default',
    element: (
      <PostsProvider>
        <Home />
      </PostsProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/comments/`,
    layout:'default',
    element: (
      <PostsProvider>
        <PostComments />
      </PostsProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/buzz`,
    layout:'default',
    element: (
      <PostsProvider>
        <Home />
      </PostsProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/home`,
    layout:'default',
    element: (
      <PostsProvider>
        <Home />
      </PostsProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/judge`,
    layout:'default',
    element: (
      <EventProvider>
        <Judge />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/audition`,
    layout:'default',
    element: (
      <EventProvider>
        <Audition />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/participants`,
    layout:'default',
    element: (
      <EventProvider>
        <Participants />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/certificates`,
    layout:'default',
    element: (
      <EventProvider>
        <Certificate />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultbadge`,
    element: <Badge />,
  },
  {
    path: `${process.env.PUBLIC_URL}/result`,
    element: <Result />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/nominations`,
  //   element: <Nominations />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/nominate`,
    element: <Nominate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leads`,
    element: <Leads />,
  },
 
  {
    path: `${process.env.PUBLIC_URL}/groups`,
    element: <Grps />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultstorie`,
    element: <Storie />,
  },
  {
    path: `${process.env.PUBLIC_URL}/winners`,
    element: <Winners />,
  },
  
  {
    path: `${process.env.PUBLIC_URL}/defaultemailbox`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultemailopen`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultsettings`,
    element: <Settings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultvideo`,
    element: <Videos />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultanalytics`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/accountinformation`,
    element: <Account />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultmember`,
    element: <Member />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contactinformation`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/socialaccount`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/password`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/payment`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultnoti`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/helpbox`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forgot-password`,
    element: <Forgot />,
    layout:'auth', 
  },
  {
    path: `${process.env.PUBLIC_URL}/login`,
    element: <Login />,
    layout:'auth', 
  },
  {
    path: `${process.env.PUBLIC_URL}/register`,
    element: <Register />,
    layout:'auth', 
    
  },
  {
    path: `${process.env.PUBLIC_URL}/forgot`,
    element: <Comingsoon />,
    layout:'default'
  },
  {
    path: `${process.env.PUBLIC_URL}/notfound`,
    element: <Notfound />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/shop1`,
  //   element: <ShopOne />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/shop2`,
  //   element: <ShopTwo />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/shop3`,
  //   element: <ShopThree />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/singleproduct`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart`,
    element: <Cart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/checkout`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultmessage`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultlive`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/`,
    element: <Home />,
    layout:'default',
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultlive`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultjob`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultevent`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaulthotel`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/grouppage`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/userpage`,
    element: <Userpage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/authorpage`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/comingsoon`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaulthoteldetails`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/terms`,
    element: <Terms />,
    layout: 'auth'
  },
  {
    path: `${process.env.PUBLIC_URL}/privacy`,
    element: <Privacy />,
    layout: 'auth'
  },
  {
    path: `${process.env.PUBLIC_URL}/community`,
    element: <Community />,
    layout: 'auth'
  },
  {
    path: `${process.env.PUBLIC_URL}/chat`,
    element: (
      <SocketContextProvider>
        <Chat />
      </SocketContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/gupshup`,
    element: (
      <SocketContextProvider>
        <BuzzProvider>
          <GupShup />
        </BuzzProvider>
      </SocketContextProvider>
    ),
    isProtected:true
  },
];
