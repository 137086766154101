import React, { Fragment, useContext, useEffect, useState } from 'react'

import ChatUsers from '../components/chat/ChatUsers'
import Popupchat from '../components/Popupchat'
import AudioRecorder from '../components/chat/audioRecorder'
import AudioPlayer from '../components/chat/audioPlayer'

import { AuthContext } from '../context/authContext'
import { SocketContext } from '../context/chatContext'
import { GupShupContext } from '../context/gupShupContext'
import alertService from '../services/sweetAlert'
import Load from "../components/Load";

export default function Buzz () {
  const { currentUser } = useContext(AuthContext)
  const { messageContainerRef, windowWidth } = useContext(SocketContext)
  const { sendMessageToGroup, group, chat, handleSendAudio, deleteMessage } =
    useContext(GupShupContext)
  const [newMessage, setNewMessage] = useState('')

  useEffect(() => {}, [])
  localStorage.setItem("redirect", "/gupshup")
  const submitHandler = e => {
    if (newMessage.length) {
      sendMessageToGroup({
        message: newMessage,
        senderId: currentUser.id,
        receiverId: group?.id,
        time: new Date(),
        msgType: 'text',
        sender: currentUser
      })
      setNewMessage('')
    }
  }

  const deleteMessageAction = async id => {
    const confirmed = await alertService.confirm(
      'Delete Message',
      'Are you sure you want to delete this message?'
    )

    if (confirmed) {
      // delete the item
      await deleteMessage(id)
      alertService.success(
        'Message Deleted',
        'The message has been successfully deleted.'
      )
    }
  }
  return (
    <Fragment>
      {windowWidth > 1000 && <ChatUsers title='Participants' />}
      <div className='main-content right-chat-active'>
        <div className='middle-sidebar-bottom'>
          <div
            className='middle-sidebar-left pe-0'
            style={{ maxWidth: '100%' }}
          >
          <Load/>
            <div className='row'>
              <div className='col-lg-12 position-relative'>
                <div
                  className='chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg'
                  ref={messageContainerRef}
                >
                  <div className='chat-body p-3 '>
                    <div className='messages-content pb-3'>
                      {chat.length
                        ? chat.map(
                            (
                              {
                                id,
                                message,
                                sender,
                                createdAt = new Date(),
                                msgType
                              },
                              index
                            ) => {
                              return (
                                <div
                                  key={`${index}${id}`}
                                  className={`message-item ${
                                    sender?.id === currentUser?.id
                                      ? 'outgoing-message'
                                      : ''
                                  }`}
                                 
                                >
                                  <div className='message-user' >
                                    <figure className='avatar'>
                                      {sender?.picture ? (
                                        <img
                                          className='rounded-img-sm mx-3'
                                          src={
                                            sender.picture.includes('googleusercontent')?sender.picture:
                                            'https://files.geetsuhane.com/users/' +
                                            sender.picture
                                          }
                                          alt=''
                                        />
                                      ) : (
                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-3'>
                                          <span className='ti-user font-sm'></span>
                                        </div>
                                      )}
                                    </figure>
                                    <div className='' verticalAlign='Top'>
                                      <div className='d-flex gap-5'>
                                        <h6>{sender?.name}</h6>
                                      </div>
                                      <div className='time'>
                                        {new Date(
                                          createdAt
                                        ).toLocaleTimeString()}
                                        ,{new Date(createdAt).toDateString()}
                                      </div>
                                      
                                    </div>
                                    
                                  </div>
                                  <div className='message-wrap justify-content-between d-flex align-items-center'>
                                        {msgType === 'audio' ? (
                                          <AudioPlayer url={message} />
                                        ) : (
                                          message
                                        )}
                                        {sender?.id === currentUser?.id && id && (
                                          <span
                                            onClick={() => {
                                              deleteMessageAction(id)
                                            }}
                                            className='ti-trash cursor-pointer'
                                          ></span>
                                        )}
                                      </div>
                                </div>
                              )
                            }
                          )
                        : ''}

                      <div className='clearfix'></div>
                    </div>
                  </div>
                </div>
                <div
                  className='chat-bottom dark-bg p-3 shadow-none theme-dark-bg'
                  style={{ width: '98%' }}
                >
                  <form
                    className='chat-form'
                    onSubmit={e => {
                      e.preventDefault()
                      const functionToCall = newMessage
                        ? submitHandler
                        : handleSendAudio
                      functionToCall()
                    }}
                  >
                    <AudioRecorder />
                    <div className='form-group'>
                      <input
                        value={newMessage}
                        onChange={e => {
                          setNewMessage(e.target.value)
                        }}
                        type='text'
                        className='w-100 bg-grey'
                        placeholder='Start typing..'
                      />
                    </div>
                    <button type='submit' className={`bg-current`}>
                      <i className='ti-arrow-right text-white'></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
    </Fragment>
  )
}
