import ApiService from "./api.service";

class AuthService {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async login(user) {
    try {
      return await this.apiService.post("/auth/loginwithsm", user);
    } catch (err) {
      console.error(err);
      throw new Error("login error with database");
    }
  }

  async register({name,email,password}) {
    try {
      return await this.apiService.post("/auth/register", {name,email,password});
    } catch (err) {
      console.error(err);
      throw new Error("login error with database");
    }
  }
  async loginWithEmailAndPassword({email,password}) {
    try {
      return await this.apiService.post("/auth/login", {email,password});
    } catch (err) {
      console.error(err);
      throw new Error("login error with database");
    }
  }

  async userExists(sub) {
    try {
      const response = await this.apiService.get(`/auth/checkuser/${sub}`);
      return response;
    } catch (err) {
      console.error(err);
      throw new Error("login error with database");
    }
  }

  async getAllUsers() {
    try {
      const response = await this.apiService.get(`/auth/users`);
      return response;
    } catch (err) {
      console.error(err);
      throw new Error("login error with database");
    }
  }

  async updareDisplayName({ userId, text }) {
    try {      
      const response = await this.apiService.post("/profile/displayName", {
        userId,
        text
      });
      alert(response.message);
      //throw new Error("Updtae was sucessful");
      //return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateProfilePic({ userId, text }) {
    try {
      //console.log("path:"+text);
      const response = await this.apiService.post("/profile/profilePic", {
        userId,
        text
      });
      alert(response.message);
      //throw new Error("Updtae was sucessful");
      //return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async album({userId}) {
    try {
      const response = await this.apiService.post("/profile/getAlbum", {
        userId
      });
      return response;
    } catch (err) {
      console.error(err);
      throw new Error("Unable to fecth album");
    }
  }

  async updateAlbum({userId, text}) {
    try {
      const response = await this.apiService.post("/profile/updateAlbum", {
        userId,
        text
      });
      return response;
      //alert(response.message);
    } catch (err) {
      //console.error(err);
      //throw new Error("Unable to update album");
    }
  }
  //

  // add other authentication methods here, such as register, logout, etc.
}

// Example usage:
const apiService = new ApiService();
const authService = new AuthService(apiService);
export default authService;
