import React, { useState, useRef, useContext } from "react";
import { AuthContext } from "../context/authContext";

import {uploadFileToStorageDo, uploadImages} from "../files";
import postService from "../services/posts.service";

export default function Createpost() {
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [fileType, setFileType] = useState(null);

  const fileInputRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileType(
      file.type.startsWith("image/")
        ? "img"
        : file.type.startsWith("video/")
        ? "video"
        : null
    );
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileContent(reader.result);
    };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const submitFunction = async () => {
    if (!currentUser) {
      return alert("you cannot post without logging in");
    }
    const saveText = async (text, url) => {
      await postService.createPost({
        text,
        mediaUrl: url,
        mediaType: fileType,
        userId: currentUser.id,
      });
      discardPost();
    };
    if (text || file) {
      setLoading(true);
      if (file) {
        const filePath = currentUser.id+'/posts/'+Date.now() +'/'+ file.name;
        const uploadTask = await uploadImages(file, filePath);
        saveText(text, filePath);
        return;
      }
      saveText(text);
    }
  };
  const discardPost = () => {
    setLoading(false);
    setText("");
    setFile("");
    setFileContent("");
    setIsOpen(false);
    setFileType(null);
  };
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3 position-relative">
      {loading && (
        <div className="position-absolute spinner">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      <div className="card-body p-0">
        <div className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center cursor-pointer justify-content-between position-relative">
          <div className="d-flex align-items-center">
            {/* <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i> */}
            <i className="font-md text-success feather-image me-2"></i>
            <span className="d-none-xs">Photo/Video</span>
          </div>
          <div
            className={`ms-auto pointer ${isOpen ? " show" : ""}`}
            id="dropdownMenu4"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={toggleOpen}
          >
            <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
          </div>
          <div
            className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${
              isOpen ? " show" : ""
            }`}
            aria-labelledby="dropdownMenu4"
          >
            <div onClick={discardPost} className="card-body p-0 d-flex">
              <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
              <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                Discard{" "}
                <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                  Delete Post
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0 mt-3 position-relative">
        <figure className="avatar position-absolute ms-2 mt-1 top-5">
          {currentUser?.photoURL ? (
            <img
              className="rounded-img-sm mx-3"
              src={currentUser ? 'https://files.geetsuhane.com/'+currentUser.photoURL : ""}
              alt=""
            />
          ) : (
            <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
              <span className="ti-user font-sm"></span>
            </div>
          )}
        </figure>
        <textarea
          name="message"
          className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
          cols="30"
          rows="10"
          placeholder="What's on your mind?"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="card-body d-flex p-0 mt-0">
        {/* <a
          href="#video"
          className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
        >
          <i className="font-md text-danger feather-video me-2"></i>
          <span className="d-none-xs">Live Video</span>
        </a> */}
        <div className="d-flex justify-content-between w-100">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div
            onClick={handleButtonClick}
            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 cursor-pointer"
          >
            <i className="font-md text-success feather-video me-2"></i>
            <span className="d-none-xs">Upload</span>
          </div>

          <div
            onClick={submitFunction}
            className="font-xssss fw-600 ls-1 text-grey-700 text-dark cursor-pointer"
          >
            Submit
          </div>
        </div>

        {/* <a
          href="#activity"
          className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
        >
          <i className="font-md text-warning feather-camera me-2"></i>
          <span className="d-none-xs">Feeling/Activity</span>
        </a> */}
      </div>

      <div className="">
        {fileContent && (
          <div className="">
            {fileType === "img" ? (
              <img className="w-25 my-4 h-25" src={fileContent} alt="" />
            ) : (
              <video controls className="w-25 my-4 h-25">
                <source src={fileContent} type="video/mp4" />
              </video>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
