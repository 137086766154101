import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { routes } from './routes'
import { AuthContext } from './context/authContext'
import Login from './pages/Login'

export default function AppRoutes () {
  const { currentUser: user, loading } = useContext(AuthContext)

  return (
    <React.Fragment>
      {loading ? (
        <div className='w-100 d-flex justify-content-center'>
          <img
            src='assets/images/gs.png'
            alt='icon'
            className='ms-2 w-25 mb-1 me-5'
          />
        </div>
      ) : (
        <Routes>
          {routes.map(item => (
            <Route
              key={item.path}
              exact
              path={item.path}
              element={
                user?.name || !item.isProtected ? item.element : <Login />
              }
            ></Route>
          ))}
        </Routes>
      )}
    </React.Fragment>
  )
}
