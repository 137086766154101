import React, { useContext, useMemo } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

//import { PostsContext } from '../context/postsContext'

dayjs.extend(relativeTime)
export default function SinglePostComment ({ item, parentId, setParentId }) {
  //const { organizeComments } = useContext(PostsContext)
  const cdn = 'https://files.geetsuhane.com/'
  //   const organizedComments = useMemo(
  //     () => organizeComments(item?.childComments),
  //     [item, item?.childComments]
  //   )
  {
    console.log('testtttttttttt'+item.content)
  }
  return (
    <div className={`px-3`}>
      <div className={`${parentId === item.id ? 'bg-grey' : ''} p-2`}>
        <div className='d-flex align-items-center'>
       
          <figure className='avatar me-3 my-0'>
            {item?.user?.picture ? (
              <img
                src={cdn + 'users/' + item?.user?.picture}
                alt='avater'
                className='shadow-sm rounded-circle w45'
              />
            ) : (
              <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm'>
                <span className='ti-user font-sm'></span>
                
              </div>              
            )}
          </figure>
          <div> 
             <div className='fw-500 text-black-500 lh-24 font-xssss mb-0 mx-1'>
             <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            {item?.user?.name}            
         </h4>  
          {item?.content}
          <div className='d-flex align-items-center'>
            <p className='my-0'>{dayjs(item.createdAt)?.fromNow()}</p>
            <div
              onClick={() => {
                setParentId(item.id);
              }}
              className='mx-4 bg-grey px-2 cursor-pointer'
            >
              Reply
            </div>
          </div>
        </div></div>
                
        </div>
      
      </div>
      {item?.childComments?.map(comment => (
        <SinglePostComment
          key={comment.id}
          item={comment}
          parentId={parentId}
          setParentId={setParentId}
        />
      ))}
    </div>
  )
}
