import React, { useState,useEffect } from "react";
import {updateUserDescription,getuserProfileData} from '../firestoreService'
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
import authService from "../services/auth.Service";
import profileService from "../services/profile.service";



const Profiledetail = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [ isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(props.text);
  const [name, setName] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [location, setLocation] = useState("")
    const [bio, setBio] = useState("")

  useEffect(() => {
    getuserProfileData().then((data)=>{
      setText(data?.description);
      setName(name);
    }
    )
  },[])

  const { currentUser } = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("uid")

 
  const profileDetails = async () => {
  //console.log(currentUser);
  var profie = await profileService.getProfile({
    uid: term?term: currentUser?.id,
  });
  //console.log(profie);
  if(profie!=null  ) {
      if(profie.length>0) {
      //console.log(profie[0].name);
      let pdetails = profie[0];
      setLocation(pdetails.location?pdetails.location:'');
      setName(pdetails.name?pdetails.name:'');
      setBio(pdetails.bio?pdetails.bio:'');      
      }
      const pdetails=profie[0];
      
  }
}
useEffect(() => {
  async function fetchProfile() {
    await profileDetails();
  }
  fetchProfile();
}, []);


  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      {/* {isEdit ? (
    <div className="card-body d-block p-4" >
    <input type="text" defaultValue={currentUser?.name} name="txtName" onChange={handleNameChange} 
    />
    <button type="button" onClick={handleSaveNameClick}
      className="btn-round-sm bg-greylight p-0 d-inline-block ms-2 mt-2 float-end text-grey-500">
        <i className="feather-save font-xss"></i>
    </button>
    </div>
    ):
    (
      <div className="card-body d-block p-4" >
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">UID - {name?name:currentUser?.name}</h4>
    
      <button type="button" onClick={handleNameEditClick} 
        className="btn-round-sm bg-greylight p-0 d-inline-block ms-2 mt-2 float-end text-grey-500">
        <i className="feather-edit-3 font-xss"></i>
      </button>
    </div>
    )
}
  {isEditing ? (
  
    <div className="card-body d-block p-4">
      <textarea value={text} onChange={handleChange} 
        className="border-0 p-0 w-100 h-[200px] font-xssss fw-500 text-grey-500 bg-white rounded-3"
        style={{ height: "120px" }}
      />
      <button type="button" onClick={handleSaveClick}
        className="btn-round-sm bg-greylight p-0 d-inline-block ms-2 mt-2 float-end text-grey-500">
          <i className="feather-save font-xss"></i>
      </button>
    </div>
  ) : (
    <div className="card-body d-block p-4">
      <h4 className="fw-700 text-grey-900 font-xssss mt-0">About </h4>
      <p className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
        {text}
      </p>

      <button type="button" onClick={handleEditClick}
        className="btn-round-sm bg-greylight p-0 d-inline-block ms-2 mt-2 float-end text-grey-500"
      >
        <i className="feather-edit-3 font-xss"></i>
      </button>
    </div>
  )} */}

  <div className="card-body border-top-xs d-flex">
    <i className="feather-lock text-grey-500 me-3 font-lg"></i>
    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
      About{" "}
      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
       {bio}
      </span>
    </h4>
  </div>

  <div className="card-body d-flex pt-0">
    <i className="feather-eye text-grey-500 me-3 font-lg"></i>
    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
      Visible{" "}
      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
        Anyone can find you
      </span>
    </h4>
  </div>
  <div className="card-body d-flex pt-0">
    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
      {location}
    </h4>
  </div>
  <div className="card-body d-flex pt-0">
    <i className="feather-users text-grey-500 me-3 font-lg"></i>
    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
      General Group
    </h4>
  </div>
</div>

  )
};

export default Profiledetail;

