import React, { Component , Fragment } from "react";



import Profiledetail from '../components/Profiledetail';
import Profilephoto from '../components/Profilephoto';
import ProfilecardThree from '../components/ProfilecardThree';
import Createpost from '../components/Createpost';
import Events from '../components/Events';
import Load from '../components/Load';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useContext} from 'react'
import { AuthContext } from "../context/authContext";
import { useState } from "react";


export default function Userpage() {
  const navigate = useNavigate()
    const { currentUser } = useContext(AuthContext);
    const [user, setUser] = useState(null);

  useEffect(() => {
    if(localStorage.getItem('userAuth')) {
    setUser(currentUser)
    }
    else
    {
      localStorage.setItem("redirect", "/userpage")
      navigate('/login')
    } 
  },[])
  return (
          <Fragment>
            {
              //console.log(currentUser)
            /* <Rightchat /> */
            }

            <div className="main-content right-chat-active">
              <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0">
                  <div className="row">
                    <div className="col-xl-12 mb-3">
                      <ProfilecardThree />
                    </div>
                    <div className='clearfix'></div>
                    <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                      <Profiledetail className="fw-500 text-grey-500 lh-24 font-xssss mb-0 mx-1"
                        user={user}
                      />                  
                    </div>
                    {/* <div>  <Profilephoto /></div> */}
                    <div className="col-xl-8 col-xxl-9 col-lg-8">
                      <Createpost />                     
                      {/* <Load /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Popupchat /> */}
          </Fragment>
        );
    }