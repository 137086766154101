import React, {Fragment} from "react";


function Terms() {


  return (
    <Fragment>
         <div className="main-content bg-white ">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                         <h1>Terms and Conditions </h1>   
    <h2>THE AGREEMENT:</h2>
    <p>
    The use of this website and services on this website provided by Get Suhane (geetsuhane.com referred to as "Website") are subject to the following Terms & Conditions, all parts, and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the use of all pages on this website (hereinafter collectively referred to as "Website") and any services provided by or on this Website ("Services").

    </p>
    <p>
<strong>DEFINITIONS</strong> <br/>
“Agreement” denotes to this Terms and Conditions and the Privacy Policy and other documents provided to you by the Website.
“We”, “us” and “our” are references to GEETSUHANE.COM.<br/>
<br/>“User”, “You” and “your” denotes the person who is accessing the website for taking or availing any service from us. User shall include the company, partnership, sole trader, person, body corporate or association taking services of this Website.
<br/>” Website” shall mean and include geetsuhane.com and any successor Website of the Company or any of its affiliates.
<br/>Parties: Collectively, the parties to this Agreement (We and You) will be referred to as Parties.
<br/>ASSENT & ACCEPTANCE
<br/>PLEASE READ THESE TERMS OF USE, OUR PRIVACY POLICY, AND ALL APPLICABLE SUPPLEMENTAL TERMS (COLLECTIVELY, THE "TERMS") CAREFULLY, AS THEY CONTAIN TERMS AND CONDITIONS THAT IMPACT YOUR RIGHTS, OBLIGATIONS AND REMEDIES IN CONNECTION WITH YOUR USE OF THE SERVICES AND CONTENT. FOR EXAMPLE, THE TERMS INCLUDE:
YOUR OBLIGATION TO COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS.
LIMITATIONS OF OUR LIABILITY TO YOU; AND
A REQUIREMENT THAT YOU PURSUE CLAIMS OR SEEK RELIEF AGAINST US (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, RATHER THAN AS A PARTICIPANT IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
YOUR ACCESS TO AND USE OF THE SERVICES IS CONDITIONED ON YOUR ACCEPTANCE OF AND COMPLIANCE WITH ALL APPLICABLE TERMS. If you do not agree to these Terms or our Privacy Policy, then please cease using the Services immediately. We reserve the right to change these Terms at any time (see “Changes to these Terms” below.) By accessing, browsing and/or using the Services after updates to these Terms have been posted, you agree to be bound by the updated Terms. THESE TERMS AND OUR PRIVACY POLICY CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND GET SUHANE.
<br/>Consequences of Non-Compliance
<br/>Your failure to comply with the Terms may result in the suspension or termination of your account and/or access to the Services and may subject you to civil and criminal penalties.
SERVICE

    </p>
<p>
The Services and the Platform are only for people 13 years old and over. 
For us to provide Geetsuhane.com to you, we may require virtual access to and/or use of your relevant device (e.g., mobile phone, tablet, or desktop computer) that you use to access Geetsuhane.com – for example, we may need to use your device's processor and storage to complete the relevant Geetsuhane.com App, or we may need to access your contact list to provide certain interactive functions within Geetsuhane.com.
<br />We will provide further information regarding how Geetsuhane.com uses and accesses your device within Geetsuhane.com or in another manner (e.g., via the relevant app store as part of the installation process for Geetsuhane.com on your device). You agree to give us such access to and use of your device, and you acknowledge that if you do not provide us with such right of use or access, we may not be able to provide Geetsuhane.com (or certain features within Geetsuhane.com) to you.
We agree to provide you with the Geetsuhane.com Service. The Service includes all of the Geetsuhane.com   features, applications, services, technologies, and software that we provide to advance Geetsuhane.com ‘s mission: To carry and bring you nearer to individuals and things you love in your life. The Service is made up of the following aspects
Offering customized freedoms to make, associate, impart, find, and offer.
People are different. We want to strengthen your relationships through shared experiences you care about. So, we build systems that try to understand who and what you and others care about, and use that information to help you create, find, join, and share in experiences that matter to you. Part of that is highlighting content, features, offers, and accounts you might be interested in, and offering ways for you to experience Geetsuhane.com, based on things you and others do on and off Geetsuhane.com.
The Services allow you to create, publish and photos and to consume photos other users have created and interact with those photos and other users. 
<br /><br />Create and share photos: In particular:
You can record or import photos into the Services. 
You can edit photos and enrich them with filters and additional elements. 
<br />You can also include content from other users in your photos, provided the creator of the respective content allows the use of their content for such purposes. Other users can use your content in their photos if you allow the use of your content for such purposes.
You can publish photos in the Services so that other users can consume your photos. Photos you publish publicly will be available on the Website.
Offering customized freedoms to make, associate, impart, find, and offer.
We create and use tools and offer assets to our local area individuals that help to make their encounters positive and comprehensive, including when we figure they may require help. We likewise have groups and frameworks that work to battle misuse and infringement of our Terms and approaches, just as hurtful and beguiling conduct. We utilize all the data we are have-including your data to attempt to keep our foundation secure. We likewise may share data about abuse or unsafe substance with other law authorization.
Creating and utilizing innovations that assist us with serving our developing local area.
<br />Organizing and analyzing information for our growing community is central to our Service. A big part of our Service is creating and using cutting-edge technologies that help us personalize, protect, and improve our Service on an incredibly large scale for a broad global community. Technologies like artificial intelligence and machine learning give us the power to apply complex processes across our Service. Automated technologies also help us ensure the functionality and integrity of our Service.
You can share your photos, or photos of others that have enabled sharing, via various messaging services and on third-party social media platforms (e.g., Instagram, Facebook, YouTube, Twitter) in accordance with the respective terms of service.
You can enter a post description, tags and various privacy settings when publishing a content.
You can enter a short biography text and profile picture in your public user profile.
Giving predictable and consistent encounters across the platform
<br />Geetsuhane.com, which share technology, systems, insights, and information-including the information we have about you to provide services that are better, safer, and more secure.
You can consume photos that other users have shared publicly or by users you follow.
The Service provides you with a customized “For You” page in which the Service selects photos to show you based on what the Service determines could be interesting for you. For further information, please see the Privacy Policy. 
<br />The Service provides other ways to find content for you to consume, e.g., a list of other users’ photos on their profile, a search function, and category selection.
You can watch live streams of other users.
Interact with other users: You can interact with users’ content and other users. In particular:
Direct messages: You can send direct messages to users if they follow you. 
<br />Likes: You can like photos, vide and contents 
<br />Comments: Subject to users’ settings, you can post comments on their photos.
Follows: You can follow users. If users restrict their profile, you can only follow users if they approve your request to follow.
<br />Ensuring access to our Service.
To operate our global Service, we must store and transfer data across our systems around the world, including outside of your country of residence. The use of this global infrastructure is necessary and essential to provide our Service. 
Research and innovation.
We use the information we must develop, test, and improve our Service and collaborate with others on research to make our Service better and contribute to the well-being of our community. This includes analyzing the data we have about our users and understanding how people use our Services, for example by conducting surveys and testing and troubleshooting new features.
<br />LICENSE TO USE WEBSITE
We may provide you with certain information because of your use of the Website or Services. Such information may include but is not limited to, documentation, data, or information developed by us, and other materials which may assist in your use of the Website or Services ("Our Materials"). Subject to this Agreement, we grant you a non-exclusive, limited, non-transferable, and revocable license to use Our Materials solely in connection with your use of the Website and Services. Our Materials may not be used for any other purpose, and this license terminates upon your cessation of use of the Website or Services or at the termination of this Agreement.
<br />USER CONTENT
<br />Content Responsibility. 
The website permits you to share content, post comments, feedback, etc. but you are solely responsible for the content posted by you. You represent that you have required permission to use the content.
When posting content to the website, please do not post content that:
contains ill-mannered, profane, abusive, racist, or hateful language or expressions, text, photographs, or illustrations that are pornographic or in poor taste, inflammatory attacks of a personal, racial, or religious nature.
is defamatory, threatening, disparaging, grossly inflammatory, false, misleading, fraudulent, inaccurate, unfair, contains exaggeration or unsubstantiated claims.
violates the privacy rights of any third party, is unreasonably harmful or offensive to any individual or community.
discriminates on the grounds of race, religion, national origin, gender, age, marital status, sexual orientation, or disability, or refers to such matters in any manner prohibited by law.
violates or inappropriately encourages the violation of any municipal, state, federal, or international law, rule, regulation, or ordinance.
uses or attempts to use another's account, password, service, or system except as expressly permitted by the Terms of use uploads or transmits viruses or other harmful, disruptive, or destructive files.
sends repeated messages related to another user and/or makes derogatory or offensive comments about another individual or repeats prior posting of the same message under multiple emails or subjects.
Any submitted content that includes, but is not limited to the following, will be refused. If repeated violations occur, we reserve the right to cancel user access to the website without advanced notice.
<br />INTELLECTUAL PROPERTY
You agree that the Website and all Services provided by us are the property of Geetsuhane.com, including all copyrights, trademarks, trade secrets, patents, and other intellectual property ("Our IP"). You agree that we own all rights, title, and interest in and to the Our IP and that you will not use Our IP for any unlawful or infringing purpose. You agree not to reproduce or distribute Our IP in any way, including electronically or via registration of any new trademarks, trade names, service marks, or Uniform Resource Locators (URLs), without express written permission from us.
To make the Website and Services available to you, you hereby grant us a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content you publish, upload, or otherwise make available to the Website ("Your Content"). We claim no further proprietary rights in your Content.
If you feel that any of your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of our users, please contact us and let us know.
<br />USER OBLIGATIONS
As a user of the Website or Services, you may be asked to register with us. When you do so, you will choose a user identifier, which may be your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable you to use the Website and Services. You must not share such identifying information with any third party, and if you discover that your identifying information has been compromised, you agree to notify us immediately in writing. An email notification will suffice. You are responsible for maintaining the safety and security of your identifying information as well as keeping us apprised of any changes to your identifying information. Providing false or inaccurate information or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.
<br />ACCEPTABLE USE
You agree not to use the Website or Services for any unlawful purpose, or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services, or general business of geetsuhane.com.
You further agree not to use the Website or Services:
<br />To harass, abuse, or threaten others or otherwise violate any person's legal rights.
To violate any of our intellectual property rights or any third party.
To upload or otherwise disseminate any computer viruses or other software that may damage the property of another.
<br />To perpetrate any fraud.
To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme.
To publish or distribute any obscene or defamatory material.
<br />To publish or distribute any material that incites violence, hate, or discrimination towards any group.
To unlawfully gather information about others.
<br />REVERSE ENGINEERING & SECURITY
You agree not to undertake any of the following actions:
a) Reverse engineer or attempt to reverse engineer or disassemble any code or software from or on the Website or Services.
b) Violate the security of the Website or Services through any unauthorized access, circumvention of encryption or other security tools, data mining, or interference to any host, user, or network.
<br />INDEMNIFICATION
You agree to defend and indemnify us and any of our affiliates (if applicable) and hold us harmless against any legal claims and demands, including reasonable attorney's fees, which may arise from or relate to your use or misuse of the Website or Services, your breach of this Agreement, or your conduct or actions. You agree that we shall be able to select its legal counsel and may participate in its defense if we wish.
<br />EXCLUSION OF LIABILITY
You understand and agree that we (A) do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties; and (B) shall not be responsible for any materials posted by us or any third party. You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers and any information provided by us or any third party.
Further, we shall not be liable for direct, indirect consequential, or any other form of loss or damage that may be suffered by a user using the geetsuhane.com Website including loss of data or information or any kind of financial or physical loss or damage.
In no event shall Get Suhane, nor its Owner, directors, employees, partners, agents, suppliers, or affiliates, be accountable for any indirect, incidental, special, eventful, or exemplary costs, including without limitation, loss of proceeds, figures, usage, goodwill, or other intangible losses, consequential from (i) your use or access of or failure to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content attained from the Service; and (iv) unlawful access, use or alteration of your transmissions or content, whether or not based on guarantee, agreement, domestic wrong (including carelessness) or any other lawful concept, whether or not we've been aware of the possibility of such damage, and even if a cure set forth herein is originated to have futile of its important purpose.
<br />SPAM POLICY
You are strictly prohibited from using the Website or any of our Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.
<br />THIRD-PARTY LINKS & CONTENT
We may occasionally post links to third-party websites or other services. You agree that we are not responsible for any loss or damage caused because of your use of any third-party services linked to or from Our Website.
<br />MODIFICATION & VARIATION
We may, from time to time and at any time without notice to you, modify this Agreement. You agree that we have the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement unless prior versions are specifically referred to or incorporated into the latest modification or variation of this Agreement.
<br />ENTIRE AGREEMENT
This Agreement constitutes the entire understanding between the Parties concerning any use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.
<br />SERVICE INTERRUPTIONS
We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that we shall have no liability for any damage or loss caused because of such downtime.
<br />TERM, TERMINATION & SUSPENSION
We may terminate this Agreement with you at any time for any reason, with or without cause. We specifically reserve the right to terminate this Agreement if you violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of us or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If you have registered for an account with Us, you may also terminate this Agreement at any time by contacting us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.
“AS IS” and “AS AVAILABLE” Disclaimer
The Service is provided to You “AS IS” and “AS AVAILABLE” and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
Without limiting the foregoing, neither the Company nor any of the company’s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or services included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
<br />NO WARRANTIES
<br />You agree that your use of the Website and Services is at your sole and exclusive risk and that any Services provided by us are on an "As Is" basis. We hereby expressly disclaim any express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website or Services will meet your needs or that the Website or Services will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. You agree that any damage that may occur to you, through your computer system, or because of the loss of your data from your use of the Website or Services is your sole responsibility and that we are not liable for any such damage or loss.
<br />LIMITATION ON LIABILITY
<br />We are not liable for any damages that may occur to you because of your use of the Website or Services, to the fullest extent permitted by law. This section applies to any claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.
<br />GENERAL PROVISIONS:
<br />JURISDICTION, VENUE & CHOICE OF LAW: The terms herein will be governed by and construed by the laws of United States without giving effect to any principles of conflicts of law. The Courts of United States shall have exclusive jurisdiction over any dispute arising from the use of the Website.
<br />ASSIGNMENT: This Agreement, or the rights granted hereunder, may not be assigned, sold, leased, or otherwise transferred in whole or part by you. Should this Agreement, or the rights granted hereunder, be assigned, sold, leased, or otherwise transferred by us, the rights, and liabilities of geetsuhane.com will bind and inure to any assignees, administrators, successors, and executors.
<br />SEVERABILITY: If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such a condition, the remainder of this Agreement shall continue in full force.
<br />NO WAIVER: If we fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part.
<br />HEADINGS FOR CONVENIENCE ONLY: Headings of parts and sub-parts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement.
<br />NO AGENCY, PARTNERSHIP, OR JOINT VENTURE: No agency, partnership, or joint venture has been created between the Parties because of this Agreement. No Party has any authority to bind the other to third parties.
<br />FORCE MAJEURE: We are not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature, and natural disasters, and other acts which may be due to unforeseen circumstances, i.e., COVID-19!
<br />ELECTRONIC COMMUNICATIONS PERMITTED: Electronic communications are permitted to both Parties under this Agreement, including e-mail. For any questions or concerns, please use the contact us form on the website or email us support@geetsuhane.com 
<br />
This document was last updated on June 1, 2023
</p>



      </div>
      </div>
      </div>
      </div>
    </Fragment>
  );
}

export default Terms;
