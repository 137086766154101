import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./authContext";
import { SocketContext } from "./chatContext";
import { handleUpload } from "../services/cloudinary.service";
import { generateUUID } from "./chatContext";
export const GupShupContext = createContext([]);

const GupShupContextProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { socket, audioBlob, setAudioBlob, messageContainerRef } =
    useContext(SocketContext);

  const [group, setGroup] = useState(null);
  const [chat, setChat] = useState([]);

  // useEffect(() => {
  //   // Scroll to bottom of messages container when messages update
  //   messageContainerRef.current.scrollTo({
  //     top:
  //       messageContainerRef.current.scrollHeight -
  //       messageContainerRef.current.clientHeight,
  //     behavior: "smooth",
  //   });
  // }, [chat, messageContainerRef]);

  useEffect(() => {
    if (currentUser?.id && socket) {
      socket.emit("joinBuzz", {
        name: currentUser?.displayName,
        id: currentUser?.id,
      });
    }
  }, [socket, currentUser]);

  useEffect(() => {
    if (socket) {
      socket.on("GroupJoined", (data) => {
        setGroup(data);
        socket.emit("getBuzzChats", data?.id);
      });
      socket.on("buzzChatUpdated", (data) => {
        setChat(data);
      });
      socket.on("groupMessageRec", (data) => {
        setChat([...chat, data]);
      });
      socket.on("messageSentToGroup", ({ custom_id, message }) => {
        setChat((prevChat) =>
          prevChat.map((msg) => {
            if (msg.custom_id === custom_id) {
              return message;
            }
            return msg;
          })
        );
      });
      socket.on("messageDeletedFromGroup", (msgId) => {
        setChat((prevChat) => prevChat.filter((msg) => msg.id !== msgId));
      });
    }
    return () => {
      if (socket) {
        socket.off("newUser");
        socket.off("GroupJoined");
        socket.off("groupMessageRec");
      }
    };
  }, [socket, group, chat]);

  const sendMessageToGroup = (message) => {
    message.custom_id = generateUUID();
    setChat([...chat, message]);
    socket.emit("sendMessageToGroup", message);
  };

  const handleSendAudio = async () => {
    const url = await handleUpload(audioBlob);
    sendMessageToGroup({
      message: url,
      senderId: currentUser.id,
      receiverId:group?.id,
      time: new Date(),
      msgType: "audio",
      sender: currentUser,
    });
    setAudioBlob(null);
  };

  const deleteMessage = (id) => {
    socket.emit("deleteMessageFromGroup", { msgId: id, to: group.id });
    // setChat((prevChat) => prevChat.filter((msg) => msg.id !== id));
  };


  return (
    <GupShupContext.Provider
      value={{ sendMessageToGroup, group, chat, handleSendAudio,deleteMessage }}
    >
      {children}
    </GupShupContext.Provider>
  );
};

export default GupShupContextProvider;
