import React from 'react'
import Header from '../components/Header'
import Leftnav from '../components/Leftnav'
import Appfooter from '../components/Appfooter'
import Popupchat from '../components/Popupchat'

export default function DefaultLayout ({ children }) {
  return (
    <React.Fragment>
      <Header />
      <Leftnav />
      {children}
      <Popupchat />
      <Appfooter />
    </React.Fragment>
  )
}
