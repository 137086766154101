import React from "react";
import ReactDOM from "react-dom";

import "./main.scss";



import * as serviceWorker from "./serviceWorker";

//context to stable the login and auth system


import App from "./App"
//const vote = `${user ? "Vote" : "Login"}`;

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.register();
